import React from 'react';
import SuperPopup from '../../SuperPopup';
import icWarning from '../../../images/icons/ic-warning.svg';
import apiFetch from '../../../src/js/fetch';

class UndoCancelSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      processing: false,
      error: null,
    };
  }

  componentDidMount() {
    const cancelSubscriptionTrigger = document.querySelector(
      '#undo-cancel-subscription'
    );
    this.openTriggerClickListener = cancelSubscriptionTrigger.addEventListener(
      'click',
      this.openModal
    );
  }

  componentWillUnmount() {
    if (this.openTriggerClickListener) {
      this.openTriggerClickListener.removeEventListener(
        'click',
        this.openModal
      );
    }
  }

  openModal = (e) => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    if (this.state.processing) return;
    this.setState({ open: false });
  };

  undoCancellation = async () => {
    this.setState({ processing: true });

    try {
      const undone = await apiFetch('/api/paddle/undo-cancel', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      window.location.href = '/profile/settings';
    } catch (error) {
      console.log('Error undoing cancellation:', error);
      this.setState({
        error:
          "Something went wrong. Please try again later, and if the issue persists, please don't hesitate to get in touch with us.",
      });
    }
    this.setState({ processing: false });
  };

  render() {
    const { open, processing, error } = this.state;
    return (
      <SuperPopup
        modalTitle='Undo cancellation'
        open={open}
        onClose={this.closeModal}
        icon={icWarning}
        title="We're happy you want to undo your cancellation"
        text='Your subscription will not be cancelled at the end of the current billing period, and it will be renewed as usual.'
        buttons={[
          { text: 'Cancel', onClick: this.closeModal },
          {
            text: 'Undo cancellation',
            onClick: this.undoCancellation,
            style: 'dark',
          },
        ]}
        processing={processing}
        error={error}
      />
    );
  }
}

export default UndoCancelSubscription;

import React from 'react';
import Modal from '../../Modal';
import InviteFriends from '.';

class InviteFriendsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      url: props.url,
      contestId: props.contest_id,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.openTrigger = document.querySelector('#open-invite');
  }

  componentDidMount() {
    console.log('mounted');

    this.openTriggerClickListener = this.openTrigger.addEventListener(
      'click',
      this.openModal
    );
  }

  componentWillUnmount() {
    console.log('unmounting');
    if (this.openTriggerClickListener) {
      this.openTrigger.removeEventListener('click', this.openModal);
    }
  }

  openModal(e) {
    this.setState({
      open: true,
    });
  }

  closeModal() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { open, contestId, url } = this.state;
    const { challenge } = this.props;
    return (
      <Modal title='Invite friends' open={open} onClose={this.closeModal}>
        <InviteFriends
          contest_id={contestId}
          url={url}
          modal={true}
          challenge={challenge}
        />
      </Modal>
    );
  }
}
export default InviteFriendsModal;

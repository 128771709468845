import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import Swal from 'sweetalert2';

document.addEventListener('turbo:load', function () {
  addTippys();
});

export const addTippys = () => {
  const tippyInstances = document.querySelectorAll(
    '[data-tippy-content]:not([data-tippy-set])'
  );
  tippyInstances.forEach(function (el) {
    el.setAttribute('data-tippy-set', 'true');
    let elem = el;
    const placement = el.dataset.tippyPlacement || 'top';
    const options = {
      content: el.dataset.tippyContent,
      allowHTML: true,
      theme: 'hurryup',
      placement: placement,
      interactive: true,
    };

    if (el.closest('.challenge-leaderboard')) {
      // Is in a challenge leaderboard table
      options.onShow = function () {
        if (window.matchMedia('(max-width: 639px)').matches) {
          // Show popup on mobile

          setTimeout(() => {
            Swal.fire({
              html: el.dataset.tippyContent,
              animation: false,
              confirmButtonText: 'Close',
            });
          }, 50);

          return false;
        }
      };
    }

    tippy(elem, options);
  });
};

import React from 'react';
import ContestSegmentsMap from '../../src/js/contests/segments';

class FrontpageFeaturedMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  componentDidMount() {
    this.mapHandler = new ContestSegmentsMap({
      mapSelector: 'frontpage-featured-map-' + this.state.slug,
    });
    this.mapHandler.addSegmentsToMap(this.state.segments);
  }

  componentWillUnmount() {
    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  render() {
    return (
      <div
        className='flex-1 map'
        id={`frontpage-featured-map-${this.state.slug}`}
      ></div>
    );
  }
}
export default FrontpageFeaturedMap;

import React, { useState } from 'react';
import lightswitch from '../../../images/icons/ic-lightswitch.svg';
import AccessItem from './AccessItem';

function Pricing({
  monthly_price: monthlyPrice,
  yearly_price: yearlyPrice,
  yearly_save_text: yearlySaveText,
  sign_up_link: signUpLink,
}) {
  const [recurrence, setRecurrence] = useState('monthly');

  const toggleRecurrence = () => {
    setRecurrence(recurrence === 'monthly' ? 'yearly' : 'monthly');
  };

  return (
    <div className='w-full max-w-text md:mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 pt-20'>
      <div className='bg-background-quaternary p-8 flex flex-col border border-border-darker-yellow rounded-2xl'>
        <div className='mb-6 md:h-[7.5rem]'>
          <h2 className='mb-4'>Free</h2>
          <p className='body-text text-text-grey-600'>Free</p>
        </div>
        <h2 className='font-medium small-body-text mb-2'>You get access to</h2>

        <ul className='mb-6 flex-1'>
          <AccessItem access={true} text='Join all public challenges' />
          <AccessItem access={false} text='Join friends leagues' />
          <AccessItem access={false} text='Join distance challenges' />
          <AccessItem access={false} text='Create public challenges' />
          <AccessItem access={false} text='Create friends leagues' />
          <AccessItem access={false} text='Create distance challenges' />
        </ul>
        <div className=''>
          <p className='small-body-text text-text-grey-600 font-medium'>
            30 days free trial. No credit card required for sign up.
          </p>
          <a href={signUpLink} className='button dark mx-auto'>
            Get started
          </a>
        </div>
      </div>
      <div className='bg-background-quaternary p-8 flex flex-col border border-border-darker-yellow rounded-2xl'>
        <div className='mb-6 md:h-[7.5rem]'>
          <h2 className='mb-4'>Full access</h2>
          <p className='body-text text-text-grey-600'>
            <span className=''>
              {recurrence == 'monthly' ? monthlyPrice : yearlyPrice}
            </span>{' '}
            / {recurrence == 'monthly' ? 'month' : 'year'}
            {/* {recurrence == 'yearly' && ` (${yearlySaveText})`} */}
          </p>
          <div className='flex items-center'>
            <button
              onClick={toggleRecurrence}
              data-view='list'
              className={`mini-label ${
                recurrence === 'monthly' ? 'font-medium' : ''
              }`}
            >
              Monthly
            </button>
            <button onClick={toggleRecurrence}>
              <img
                src={lightswitch}
                className={`mx-2 w-7 ${
                  recurrence === 'monthly' ? '' : 'rotate-180'
                }`}
              />
            </button>
            <button
              onClick={toggleRecurrence}
              data-view='map'
              className={`mini-label text-left ${
                recurrence === 'yearly' ? 'font-medium' : ''
              }`}
            >
              Yearly{' '}
              <span className='font-medium text-purple'>
                ({yearlySaveText})
              </span>
            </button>
          </div>
        </div>
        <h2 className='font-medium small-body-text mb-2'>You get access to</h2>
        <ul className='mb-6'>
          <AccessItem access={true} text='Join all public challenges' />
          <AccessItem access={true} text='Join friends leagues' />
          <AccessItem access={true} text='Join distance challenges' />
          <AccessItem access={true} text='Create public challenges' />
          <AccessItem access={true} text='Create friends leagues' />
          <AccessItem access={true} text='Create distance challenges' />
        </ul>
        <div className=''>
          <p className='small-body-text text-text-grey-600 font-medium'>
            30 days free trial. No credit card required for sign up.
          </p>
          <a href={signUpLink} className='button dark mx-auto'>
            Get started
          </a>
        </div>
      </div>
    </div>
  );
}

export default Pricing;

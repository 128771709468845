import React from 'react';
import Pagination from '../../Pagination';
import BarLoader from 'react-spinners/BarLoader';
import apiFetch from '../../../src/js/fetch';
import Item from './Item';
import Splash from '../../Splash';

class Browse extends React.Component {
  constructor(props) {
    super(props);

    const url = new URL(window.location.href);
    const geoSupport = !!navigator.geolocation;

    this.state = {
      numPages: 0,
      currentPage: url.searchParams.get('page') || 1,

      country: url.searchParams.get('country') || 'all',
      order: url.searchParams.get('order') || (geoSupport ? 'nearest' : 'date'),
      sport: url.searchParams.get('sport') || 'all',

      countries: props.countries,
      segmentTypes: props.segment_types,

      allowLocation: false,
      locationLoading: true,
      geoSupport: geoSupport,
      locationLat: null,
      locationLng: null,

      loading: true,
      error: null,
      errorTitle: null,

      challenges: [],
    };
  }

  componentDidMount() {
    if (this.state.geoSupport) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState(
            {
              locationLat: position.coords.latitude,
              locationLng: position.coords.longitude,
              allowLocation: true,
              locationLoading: false,
            },
            this.search
          );
        },
        () => {
          this.setState(
            {
              allowLocation: false,
              locationLoading: false,
              locationLat: null,
              locationLng: null,
            },
            this.search
          );
        }
      );
    }
  }

  onPaginate = (page) => {
    this.setState({ currentPage: page }, this.search);
  };

  onChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (this.state.loading) return;

    this.setState(
      { [name]: value, currentPage: 1, error: null, errorTitle: null },
      this.search
    );
  };

  search = async () => {
    const {
      currentPage,
      country,
      order,
      sport,
      locationLat,
      locationLng,
      allowLocation,
    } = this.state;

    // Update URL
    const url = new URL(window.location.href);
    url.searchParams.set('page', currentPage);
    url.searchParams.set('order', order);
    url.searchParams.set('sport', sport);
    url.searchParams.set('country', country);

    window.history.replaceState({}, {}, url);

    if (order === 'nearest' && !allowLocation) {
      this.setState({
        error:
          'To order by distance, please grant location access in your browser or change the "Order by" filter. Your location will solely be used to identify nearby challenges and segments. Refresh the page after enabling location.',
        errorTitle: 'Location access disabled',
        loading: false,
      });
      return;
    }

    this.setState({ loading: true });

    // Fetch data
    const body = {
      page: currentPage,
      order: order,
      segment_type_id: sport,
      country: country,
      slim: window.matchMedia('(max-width: 639px)').matches,
    };
    if (order === 'nearest') {
      body.lat = locationLat;
      body.lng = locationLng;
    }

    try {
      const data = await apiFetch(`/api/challenges/browse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      // console.log({ data });
      this.setState(
        {
          numPages: data.total_pages,
          challenges: data.challenges,
          loading: false,
        },
        () => {
          const list = document.querySelector('.challenges__list');
          if (!list) return;
          window.scrollTo({
            top: list.getBoundingClientRect().top + window.scrollY - 100,
            behavior: 'smooth',
          });
        }
      );
    } catch (e) {
      console.log({ e });
      const err = await e.json();
      console.log({ err });
      this.setState({
        loading: false,
        error:
          err.error_message || 'Something went wrong. Please try again later.',
        errorTitle: null,
      });
    }
  };

  render() {
    const {
      numPages,
      currentPage,
      sport,
      country,
      order,
      allowLocation,
      locationLoading,
      geoSupport,
      loading,
      countries,
      segmentTypes,
      challenges,
      error,
      errorTitle,
    } = this.state;

    // console.log({ order, allowLocation, locationLoading, geoSupport, loading });
    return (
      <section className='w-full mb-20'>
        <div className='flex flex-wrap'>
          <div className='challenges__filter md:mr-8 mb-2'>
            <span className='mini-text block mb-2'>Sport:</span>
            <div className='flex flex-wrap'>
              <label
                htmlFor='all'
                className={`challenges__filter-item mb-2 ${
                  sport === 'all' ? 'active' : ''
                }`}
              >
                All
              </label>
              <input
                type='radio'
                id='all'
                name='sport'
                value='all'
                className='hidden'
                onChange={this.onChange}
              />

              {segmentTypes.map((s) => (
                <>
                  <label
                    htmlFor={s.handle}
                    className={`challenges__filter-item mb-2 ${
                      parseInt(sport) === s.id ? 'active' : ''
                    }`}
                    key={s.handle + '-label'}
                  >
                    {s.name}
                  </label>
                  <input
                    key={s.handle + '-input'}
                    type='radio'
                    id={s.handle}
                    name='sport'
                    value={s.id}
                    className='hidden'
                    onChange={this.onChange}
                  />
                </>
              ))}
            </div>
          </div>

          <div className='challenges__filter mr-2 md:mr-8 mb-6'>
            <span className='mini-text block mb-2'>Country:</span>
            <div>
              <select
                name='country'
                className='input'
                onChange={this.onChange}
                value={country}
              >
                <option value='all'>All</option>
                {countries.map((c) => (
                  <option key={c[1]} value={c[1]}>
                    {c[0]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='challenges__filter mr-2 md:mr-8 mb-6'>
            <span className='mini-text block mb-2'>Order by:</span>
            <div>
              <select
                name='order'
                className='input'
                onChange={this.onChange}
                value={order}
              >
                {geoSupport && (
                  <option value='nearest'>Nearest location</option>
                )}
                <option value='date'>Date</option>
                <option value='popularity'>Popularity</option>
              </select>
            </div>
          </div>
        </div>
        {loading ? (
          <BarLoader />
        ) : (
          <div>
            {error ? (
              <Splash title={errorTitle} text={error} />
            ) : challenges && challenges.length > 0 ? (
              <div className='challenges__list grid grid-cols-1 lg:grid-cols-2 gap-4 xl:grid-cols-3 2xl:grid-cols-4'>
                {challenges.map((challenge, i) => (
                  <Item key={challenge.id} challenge={challenge} />
                ))}
              </div>
            ) : (
              <Splash title='No challenges to see.'>
                <p className='body-text'>
                  <a href='/challenges/new' className='underline'>
                    Create challenge
                  </a>
                </p>
              </Splash>
            )}
          </div>
        )}
        <Pagination
          key={currentPage}
          initialPage={currentPage - 1}
          numPages={numPages}
          currentPage={currentPage}
          onPaginate={this.onPaginate}
          loading={loading}
        />
      </section>
    );
  }
}
export default Browse;

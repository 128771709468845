import React, { useState } from 'react';
import SuperPopup from '../SuperPopup';

const Install = () => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('iOS');

  const onClose = () => {
    setOpen(false);
  };
  const onClick = () => {
    setOpen(true);
  };
  const setIos = () => {
    setTab('iOS');
  };
  const setAndroid = () => {
    setTab('android');
  };

  return (
    <>
      <button onClick={onClick} className='menu__item menu__item-appinstall'>
        Install the App
      </button>
      <SuperPopup
        modalTitle={'Install the App'}
        open={open}
        onClose={onClose}
        py='pb-16'
      >
        <div className='flex'>
          <button
            onClick={setIos}
            className={`tab ${tab === 'iOS' ? 'tab-active' : ''}`}
          >
            iOS
          </button>
          <button
            onClick={setAndroid}
            className={`tab ${tab === 'android' ? 'tab-active' : ''}`}
          >
            Android
          </button>
        </div>
        {tab === 'iOS' ? (
          <>
            <p className='text-text-body text-left'>
              1. Tap the "Share" icon (the square with an upward arrow): This is
              located at the bottom center of the screen.
              <br />
              <br />
              2. Select "Add to Home Screen" from the options: Scroll down until
              you see the "Add to Home Screen" option and tap it.
              <br />
              <br />
              3. Name the app: You can rename the App if you prefer a different
              title. By default, it will use HurryUp.
              <br />
              <br />
              4. Tap "Add": The App icon will now appear on your home screen.
              <br />
              <br />
              5. Open the App from your home screen: Just tap the icon to launch
              the PWA. It will run like a native app, without the Safari browser
              bar.
            </p>
          </>
        ) : (
          <>
            <p className='text-text-body text-left'>
              1. Look for the installation prompt: In Chrome: When you load the
              HurryUp, you may see a banner at the bottom of the screen saying,
              "Add to Home Screen." Tap this. If no banner appears, use the
              browser menu: Click the three vertical dots (menu) in the
              upper-right corner, then select "Add to Home screen."
              <br />
              <br />
              2. Confirm installation: You'll be asked to confirm. Tap "Add" to
              place the App icon on your home screen.
              <br />
              <br />
              3. Open the App from your home screen: The App will now appear on
              your home screen like any other app. You can open it by tapping
              the icon, and it will launch in its own window without the browser
              interface.
            </p>
          </>
        )}
        <p className='text-left'>
          Having trouble? Read more in our{' '}
          <a href='/faq' className='underline'>
            FAQ section
          </a>
          .
        </p>
      </SuperPopup>
    </>
  );
};

export default Install;

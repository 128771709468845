import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import apiFetch from '../../src/js/fetch';
import Segment from '../../src/js/segments/show';
import Altitude from './Altitude';

class SegmentShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sample_file: props.sample_file,
      distance: props.distance,
    };
  }

  componentDidMount() {
    this.initMap();
  }

  async initMap() {
    const { sample_file } = this.state;

    let coords = null;
    if (sample_file) {
      // Fetch the coordinates.
      const d = await apiFetch(sample_file);
      if (d && d.coordinates) {
        coords = d.coordinates.map((c) => L.latLng(c.lat, c.lng, c.alt));
      }
    }

    if (coords) {
      // init map
      this.mapHandler = new Segment({
        coordinates: coords,
        seeCurrentLocation: true,
      });
      this.setState({ coordinates: coords });
    }
  }

  componentWillUnmount() {
    // stop polling
    console.log('unmounting');

    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  render() {
    const { coordinates, distance } = this.state;
    return (
      <>
        <div id='map' className='map'></div>

        {coordinates ? (
          <Altitude coordinates={coordinates} segmentDistance={distance} />
        ) : null}
      </>
    );
  }
}
export default SegmentShow;

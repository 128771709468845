import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';

import 'leaflet-geometryutil/src/leaflet.geometryutil.js';
import 'leaflet-linestring-select/dist/L.Control.LineStringSelect.min.js';
import segmentStartIcon from '../../images/icons/ic-segment-start.svg';
import segmentEndIcon from '../../images/icons/ic-segment-end.svg';
import 'leaflet.heightgraph';
import FitRead from './fit/Read';
import coordinatesDistance from './lib/coordinatesDistance';

import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

/**
 * CreateSegments
 * This class is used to create segments from a users activity.
 *
 * It will load the map and draw the activity on the map.
 *
 * @param {Object} options
 * @param {Object} options.geoJson
 * @param {Array} options.coordinates
 * @param {Array} options.startLocation
 * @param {Number} options.startZoom
 * @param {String} options.mapSelector
 */
export default class MapHandler {
  constructor({
    geoJson,
    coordinates,
    startLocation = [59.9095081, 10.7250473], // Oslo
    startZoom = 14,
    maxZoom = 19,
    mapSelector = 'map',
    selectControlEnabled = false,
    selectionCallback = null,
    polylineOptions = null,
    expandMapEnabled = false,
    expandMapCallback = null,
    seeCurrentLocation = false,
  } = {}) {
    // Polyline options
    this.polylineOptions = polylineOptions;

    // Setting
    this.selectControlEnabled = selectControlEnabled;
    this.selectionCallback = selectionCallback;
    this.expandMapEnabled = expandMapEnabled;
    this.expandMapCallback = expandMapCallback;
    this.seeCurrentLocation = seeCurrentLocation;

    // GeoJson/Coordinates
    this.geoJson = geoJson;
    this.coordinates = coordinates ? coordinates : null;

    // Map
    this.map = L.map(mapSelector, {
      gestureHandling: window.matchMedia('(max-width: 639px)').matches,
      minZoom: 7,
      maxZoom: maxZoom,
      zoomControl: false,
    }).setView(startLocation, startZoom);

    // Select control
    this.selectControl = new L.Control.LineStringSelect({
      movingMarkerStyle: { opacity: 0 },
      endpointStyle: { opacity: 0 },
      selectionStyle: { color: '#2C3232', weight: 2, opacity: 1 },
    });
    this.selectionData = {};
    this.startMarker = null;
    this.endMarker = null;
    this.startMarkerIcon = L.icon({
      iconUrl: segmentStartIcon,
      iconSize: [20, 24],
      iconAnchor: [10, 24],
      alt: 'Start point',
      keyboard: false,
    });
    this.endMarkerIcon = L.icon({
      iconUrl: segmentEndIcon,
      iconSize: [20, 20],
      alt: 'End point',
      keyboard: false,
    });

    // Data
    this.coordinatesDistance = this.coordinates
      ? this.calculateDistance(this.coordinates)
      : null;

    // DOM elements (?)
    this.segmentInfoDistance = document.querySelector('#segment-length');

    // bindings
    this.setup = this.setup.bind(this);
    this.selectionListener = this.selectionListener.bind(this);
    this.loadedFileData = this.loadedFileData.bind(this);
    this.setFitFile = this.setFitFile.bind(this);
    this.drawPolyline = this.drawPolyline.bind(this);
    this.getPositionSuccess = this.getPositionSuccess.bind(this);
    this.getPositionError = this.getPositionError.bind(this);
    this.getPositionErrorWatch = this.getPositionErrorWatch.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.handleOrientation = this.handleOrientation.bind(this);

    this.setup();
  }

  /*
   * =========================================================
   * Getters and setters
   */

  getBounds() {
    return this.map.getBounds();
  }
  getCoordinates() {
    return this.coordinates;
  }

  getSelection() {
    return this.selectionData;
  }
  getSelectionGeoJson() {
    return this.selectControl.toGeoJSON();
  }

  getZoom() {
    return this.map.getZoom();
  }

  // Read and prepare the coordinates from the fit file
  setFitFile(file) {
    const callback = this.loadedFileData;
    new FitRead(file, callback);
  }

  /*
   * =========================================================
   * Map methods
   */
  // Adds the map to the page
  setup() {
    // Adds tile to map
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap',
    }).addTo(this.map);

    // Draws coordinates immediately if coordinates are set
    if (this.coordinates) {
      console.log('Drawing coordinates');

      this.drawPolyline(
        this.coordinates.map((c) => L.latLng(c.lat, c.lng, c.altitude))
      );
    }

    if (this.expandMapEnabled && this.expandMapCallback) {
      // Create the expand map button
      const self = this; // We need to use self as "this" is not available in the onAdd function
      L.Control.Button = L.Control.extend({
        options: {
          position: 'topright',
        },
        onAdd: function (map) {
          var container = L.DomUtil.create(
            'div',
            'leaflet-bar leaflet-control'
          );
          var button = L.DomUtil.create(
            'a',
            'leaflet-control-expand',
            container
          );
          L.DomEvent.on(button, 'click', function () {
            self.expandMapCallback();
          });

          container.title = 'Expand map control';

          return container;
        },
        onRemove: function (map) {},
      });
      // Add the expand map control to the map
      this.expandMapControl = new L.Control.Button();
      this.expandMapControl.addTo(this.map);
    }

    if (this.seeCurrentLocation) {
      /*
       * Show my position control
       */
      const self = this; // We need to use self as "this" is not available in the onAdd function
      const customControl = L.Control.extend({
        options: {
          position: 'topleft',
        },

        onAdd: function (map) {
          const container = L.DomUtil.create('div');
          container.classList.add('flex');

          const positionControl = L.DomUtil.create('button');
          const positionSpanControl = L.DomUtil.create('span');
          positionSpanControl.innerHTML = 'Show my position';
          positionControl.appendChild(positionSpanControl);

          positionControl.classList.add('show-position-control');
          positionControl.onclick = function () {
            positionSpanControl.innerHTML = 'Locating';
            self.positionControl = positionSpanControl;
            console.log('Locating');
            self.getPosition();
          };

          container.appendChild(positionControl);

          return container;
        },
      });
      this.map.addControl(new customControl());
    }

    // Add zoom control
    L.control
      .zoom({
        position: 'topleft',
      })
      .addTo(this.map);
  }

  // Draws the polyline on the map
  drawPolyline(coords, flyTo = true) {
    // Creates the polyline and adds it to the map
    const ride = L.polyline(
      coords,
      this.polylineOptions || {
        color: '#2C3232',
        opacity: this.selectControlEnabled ? 0.6 : 1,
        weight: 3,
      }
    ).addTo(this.map);

    // Adds the start and end icons
    let startIconLayer = null;
    let stopIconLayer = null;

    // Only add start and end icons if selectControl is disabled
    if (!this.selectControlEnabled) {
      const startIcon = L.icon({
        iconUrl: segmentStartIcon,
        iconSize: [20, 24],
        iconAnchor: [10, 24],
      });
      startIconLayer = L.marker(coords[0], { icon: startIcon }).addTo(this.map);

      const endIcon = L.icon({
        iconUrl: segmentEndIcon,
        iconSize: [20, 20],
      });

      stopIconLayer = L.marker(coords[coords.length - 1], {
        icon: endIcon,
      }).addTo(this.map);
    }

    // Adds the selection control if enabled
    if (this.selectControlEnabled) {
      // Adds and enables LineStringSelect
      this.map.addControl(this.selectControl);
      this.selectControl.enable({
        feature: ride.feature,
        layer: ride,
      });
      this.selectControl.on('selection', this.selectionListener);

      // Set the selection to 25% to 75% of the total distance
      this.startMarker = L.marker(coords[0], {
        icon: this.startMarkerIcon,
        interactive: false,
      }).addTo(this.map);

      this.selectControl.selectMeters(
        this.coordinatesDistance * 0.25,
        this.coordinatesDistance * 0.75
      );

      // Fit map to the selection
      this.map.fitBounds(this.selectControl._selection.getBounds());
    }

    // Fly to the start point if specified
    if (flyTo && !this.selectControlEnabled) {
      // Fly the focus to the start-point
      this.flyTo([this.coordinates[0].lat, this.coordinates[0].lng]);
    }

    // Return the polyline and the start and end icon references.
    return {
      polyline: ride,
      stopIcon: stopIconLayer,
      startIcon: startIconLayer,
    };
  }

  /*
   * =========================================================
   * Geolocation methods
   */
  async getPosition() {
    if ('geolocation' in navigator) {
      /* geolocation is available */
      console.log('Getting position');
      navigator.geolocation.getCurrentPosition(
        this.getPositionSuccess,
        this.getPositionError
      );
      if (!this.positionWatcher) {
        this.positionWatcher = navigator.geolocation.watchPosition(
          this.getPositionSuccess,
          this.getPositionErrorWatch
        );
      }
    } else {
      alert(
        'Your browser does not support geolocation. Please use another browser to use your position.'
      );
    }

    // get the orientation of the device
    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      typeof DeviceOrientationEvent.requestPermission === 'function'
    ) {
      try {
        const permissionState =
          await DeviceOrientationEvent.requestPermission();

        if (permissionState === 'granted') {
          this.orientationListener = window.addEventListener(
            'deviceorientation',
            this.handleOrientation,
            true
          );
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      // handle regular non iOS 13+ devices
      console.log('Regular device');
      this.orientationListener = window.addEventListener(
        'deviceorientation',
        this.handleOrientation,
        true
      );
    }
  }

  handleOrientation(event) {
    console.log({ event });

    if (this.positionMarker) {
      this.positionMarker._icon.classList.add('has-orientation');
      this.orientationElement.style.transform = `rotate(-${event.alpha}deg)`;
    } else {
      // save the initial orientation, so we can rotate the marker when it's added to the map.
      this.initialOrientation = event.alpha;
    }
  }

  async getPositionSuccess(position) {
    console.log('Found position');

    this.positionControl.innerHTML = 'Show my position';

    if (this.positionMarker) {
      // Move the marker to the new position
      this.positionMarker.setLatLng([
        position.coords.latitude,
        position.coords.longitude,
      ]);
    } else {
      // Create a new marker
      this.positionMarker = new L.Marker(
        [position.coords.latitude, position.coords.longitude],
        {
          icon: new L.DivIcon({
            className: 'map-position-marker',
            html: '<div class="map-position-orientation"></div><div class="map-position-marker-inner"></div>',
          }),
        }
      ).addTo(this.map);

      // Save the orientation element
      this.orientationElement = this.positionMarker._icon.querySelector(
        '.map-position-orientation'
      );

      // Fly to the position
      this.flyTo([position.coords.latitude, position.coords.longitude]);

      // Rotate the marker if we have an initial orientation
      if (this.initialOrientation) {
        this.positionMarker._icon.classList.add('has-orientation');
        this.orientationElement.style.transform = `rotate(-${this.initialOrientation}deg)`;
        this.initialOrientation = null;
      }
    }
  }

  getPositionError(e) {
    console.error({ e });

    this.positionControl.innerHTML = 'Show my position';

    alert(
      'Unable to retrieve your location. Please allow your browser to use your position.'
    );
  }
  getPositionErrorWatch(e) {
    console.log({ e });
  }

  /*
   * =========================================================
   * Fit file methods
   */
  // This is called as a callback when the fit file is loaded and parsed
  loadedFileData(coordinates) {
    this.coordinates = coordinates;

    // Draw the activity on the map
    this.drawPolyline(this.coordinates);
  }

  /*
   * =========================================================
   * Helpers
   */
  // Calculate distance through all provided coordinates
  calculateDistance(coordinates) {
    return coordinatesDistance(coordinates);
    // let d = 0;
    // for (let i = 0; i < coordinates.length - 2; i++) {
    //   const pointA = point([coordinates[i].lng, coordinates[i].lat]);
    //   const pointB = point([coordinates[i + 1].lng, coordinates[i + 1].lat]);

    //   // Add the distance between the two points to the total distance (is returned in kilometers, so we multiply by 1000 to get meters)
    //   d += distance(pointA, pointB) * 1000;
    // }
    // return d;
  }

  // Fly to a specific latLng
  flyTo(latLng) {
    this.map.setView(latLng, 14);
  }

  /*
   * =========================================================
   * Selection control methods
   */

  /**
   * Fired when "selection"-event is fired, when range is changed (range slider), and when we nudge the start or end marker.
   *
   * We then check if the start or end marker is nudged or selection changed, as it requires different calculations.
   */
  async selectionListener() {
    const coordinates = this.selectControl.getSelection();
    console.log({
      control: this.selectControl,
      coords: this.selectControl.getSelection(),
    });

    // Calculate distance through all selected coordinates
    const distance = parseInt(L.GeometryUtil.length(coordinates).toFixed(0));

    // Get the percentage of the start and end markers distance from start
    let startMarkerDistanceFromStartPercentage =
      (this.startMarkerDistanceFromStart / this.coordinatesDistance) * 100;
    let endMarkerDistanceFromStartPercentage =
      (this.endMarkerDistanceFromStart / this.coordinatesDistance) * 100;
    let range = [];

    // The selection was changed by using the nudge buttons, or the range slider
    if (this.nudged || this.rangeUpdated) {
      // Reset the nudge/rangeUpdated flag
      this.nudged = false;
      this.rangeUpdated = false;

      // Use existing start and end marker values
      // Get the percentage of the start and end markers distance from start
      startMarkerDistanceFromStartPercentage =
        (this.startMarkerDistanceFromStart / this.coordinatesDistance) * 100;
      endMarkerDistanceFromStartPercentage =
        (this.endMarkerDistanceFromStart / this.coordinatesDistance) * 100;

      // Range slider values
      range = [
        startMarkerDistanceFromStartPercentage,
        endMarkerDistanceFromStartPercentage,
      ];
    } else {
      // The selection was changed by dragging the start or end marker on the map

      // Calculate the selection points distance from start of activity (layer).
      // Get first part of the coordinates array up to the start marker
      let firstPart = this.coordinates.slice(
        0,
        this.selectControl._startMarker.start
      );
      firstPart = firstPart.map((c, i) => L.latLng(c.lat, c.lng)); // Get only latlngs

      // Get distance from start
      this.startMarkerDistanceFromStart = L.GeometryUtil.length(firstPart);
      // As the start marker is not included in the coordinates array (it can be between to coordinates in the array), we need to add the distance from the start marker to the previous coordinate in the array (this.selectControl._startMarker.start)
      this.startMarkerDistanceFromStart += this.selectControl._startMarker
        .getLatLng()
        .distanceTo(
          L.latLng(
            this.coordinates[this.selectControl._startMarker.start].lat,
            this.coordinates[this.selectControl._startMarker.start].lng
          )
        ); // Get distance from startMarker to the index in the initial coordinates array

      // Get the distance from start of activity to end marker.
      this.endMarkerDistanceFromStart =
        this.startMarkerDistanceFromStart + distance;

      // Get the percentage of the start and end markers distance from start
      startMarkerDistanceFromStartPercentage =
        (this.startMarkerDistanceFromStart / this.coordinatesDistance) * 100;
      endMarkerDistanceFromStartPercentage =
        (this.endMarkerDistanceFromStart / this.coordinatesDistance) * 100;

      // Range slider values
      range = [
        startMarkerDistanceFromStartPercentage,
        endMarkerDistanceFromStartPercentage,
      ];
    }

    // Update the view
    this.updateView({ coordinates });

    // Set meta data and call callback function
    this.setMetaData(coordinates, distance, range);
  }

  // Update the view with new data
  updateView({ coordinates }) {
    // Set markers
    this.startMarker.setLatLng(coordinates[0]);
    if (this.endMarker) {
      this.endMarker.setLatLng(coordinates[coordinates.length - 1]);
    } else {
      this.endMarker = L.marker(coordinates[coordinates.length - 1], {
        icon: this.endMarkerIcon,
        interactive: false,
      }).addTo(this.map);
    }
  }

  // Set segment meta data to be used when saving segment
  setMetaData(coordinates, distance, range) {
    this.selectionData = {
      startPoint: coordinates[0],
      stopPoint: coordinates[coordinates.length - 1],
      distance: distance,
      coordinates: coordinates,
      range,
    };

    // Call the callback function if it is set
    if (this.selectionCallback) {
      this.selectionCallback(this.selectionData);
    }
  }

  // We can set the selection by providing percentages of the total distance
  setSelection(percentagesArray) {
    console.log('setSelection:', percentagesArray);
    // percentagesArray is an array of two numbers between 0 and 100.
    // We calculate the distance from start for both the two percentages and set the selection to that distance.
    this.startMarkerDistanceFromStart =
      (this.coordinatesDistance / 100) * percentagesArray[0];
    this.endMarkerDistanceFromStart =
      (this.coordinatesDistance / 100) * percentagesArray[1];

    console.log({
      start: this.startMarkerDistanceFromStart,
      end: this.endMarkerDistanceFromStart,
    });

    // Set the selection
    this.selectControl.selectMeters(
      this.startMarkerDistanceFromStart,
      this.endMarkerDistanceFromStart
    );

    // Fly to the selection
    this.map.flyToBounds(this.selectControl._selection.getBounds());

    this.rangeUpdated = true;
  }

  // Nudge the selection back and forth in small steps
  nudgeSelection(marker, direction) {
    console.log({
      start: this.startMarkerDistanceFromStart,
      end: this.endMarkerDistanceFromStart,
    });
    if (marker === 'start') {
      // Start marker
      if (direction === 'forward' || direction == 'forward-big') {
        const distanceToNudge = direction === 'forward-big' ? 30 : 3;
        if (
          this.startMarkerDistanceFromStart <
            this.endMarkerDistanceFromStart - distanceToNudge &&
          this.startMarkerDistanceFromStart <
            this.coordinatesDistance - distanceToNudge
        ) {
          // Nudge forward
          this.startMarkerDistanceFromStart =
            this.startMarkerDistanceFromStart + distanceToNudge;

          this.nudged = true;
        } else {
          // Don't nudge if start marker is closer than 2 meters to end marker.
        }
      } else {
        // back
        const distanceToNudge = direction === 'backward-big' ? 30 : 3;
        if (this.startMarkerDistanceFromStart > distanceToNudge) {
          this.startMarkerDistanceFromStart =
            this.startMarkerDistanceFromStart - distanceToNudge;
          this.nudged = true;
        } else if (this.startMarkerDistanceFromStart > 0) {
          this.startMarkerDistanceFromStart = 0;
          this.nudged = true;
        } else {
          // Don't nudge
        }
      }
    } else {
      // End marker

      if (direction === 'forward' || direction == 'forward-big') {
        const distanceToNudge = direction === 'forward-big' ? 30 : 3;
        if (
          this.endMarkerDistanceFromStart <
          this.coordinatesDistance - distanceToNudge
        ) {
          // Nudge forward
          this.endMarkerDistanceFromStart =
            this.endMarkerDistanceFromStart + distanceToNudge;
          this.nudged = true;
        } else if (this.endMarkerDistanceFromStart < this.coordinatesDistance) {
          this.endMarkerDistanceFromStart = this.coordinatesDistance;
          this.nudged = true;
        } else {
          // Don't nudge
        }
      } else {
        // back
        const distanceToNudge = direction === 'backward-big' ? 30 : 3;
        if (
          this.endMarkerDistanceFromStart >
            this.startMarkerDistanceFromStart + distanceToNudge &&
          this.endMarkerDistanceFromStart > distanceToNudge
        ) {
          this.endMarkerDistanceFromStart =
            this.endMarkerDistanceFromStart - distanceToNudge;
          this.nudged = true;
        } else {
          // Don't nudge
        }
      }
    }

    console.log({
      start: this.startMarkerDistanceFromStart,
      end: this.endMarkerDistanceFromStart,
    });

    // Set the selection
    this.selectControl.selectMeters(
      this.startMarkerDistanceFromStart,
      this.endMarkerDistanceFromStart
    );

    // Fly to the selection
    // this.map.flyToBounds(this.selectControl._selection.getBounds());
  }

  /*
   * =========================================================
   * Other methods
   */
  // We should unmout the map when we are done with it.
  unmount() {
    this.map.remove();
    if (this.orientationListener) {
      window.removeEventListener('deviceorientation', this.handleOrientation);
    }
    if (this.positionWatcher) {
      navigator.geolocation.clearWatch(this.positionWatcher);
    }
  }
}

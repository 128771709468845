import React from 'react';
import icShare from '../../images/icons/ic-share-challenge.svg';
import { event as plausibleEvent } from '../../src/js/lib/plausible';

function ShareContest({ title, url, contestants, segments }) {
  const segmentPlural = segments > 1 ? 'segments' : 'segment';
  const contestantPlural =
    contestants > 1 ? 'other participants' : 'other participant';
  const shareData = {
    title: `Join '${title}'`,
    text: `Connect your device, complete the ${segments} ${segmentPlural} and collect points for the overall leaderboard! Join ${contestants} ${contestantPlural} here: `,
    url: `${url}`,
  };

  const handleClick = async (e) => {
    try {
      await navigator.share(shareData);
      plausibleEvent('Challenge - Share');
    } catch (err) {
      console.log('Canceled share');
    }
  };

  return (
    <button onClick={handleClick} className='flex'>
      <img src={icShare} className='btn_img_classes mr-1 w-6' />
      Share
    </button>
  );
}

export default ShareContest;

import React from 'react';
import SuperPopup from '../SuperPopup';
import icWarning from '../../images/icons/ic-warning.svg';
import apiFetch from '../../src/js/fetch';

class DeleteAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      processing: false,
      error: null,
      title: 'Are you sure you want to delete your account?',
      text: 'Deleting your HurryUp account will also delete all your data and unsign you from all challenges (you’ll also be removed from all leaderboards). This action can’t be reverted.',
      success: false,
    };
  }

  componentDidMount() {
    const deleteAccountTrigger = document.querySelector('#delete-account');
    this.openTriggerClickListener = deleteAccountTrigger.addEventListener(
      'click',
      this.openModal
    );
  }

  componentWillUnmount() {
    if (this.openTriggerClickListener) {
      this.openTriggerClickListener.removeEventListener(
        'click',
        this.openModal
      );
    }
  }

  openModal = (e) => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    if (this.state.processing) return;
    this.setState({ open: false });
  };

  deleteAccount = async () => {
    this.setState({ processing: true });

    try {
      const deleted = await apiFetch('/api/delete-user', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // window.location.href = deleted.redirect_url;
      this.setState({
        success: true,
        title: "We're sad to see you go..",
        text: "Your account deletion has been scheduled, and we will notify you once it has been completed. You will be logged out when the account and it's data is deleted.",
      });
    } catch (error) {
      console.log('Error deleting user:', error);
      const err = await error.json();
      this.setState({
        error:
          err.error_message || 'Something went wrong. Please try again later.',
      });
    }
    this.setState({ processing: false });
  };

  render() {
    const { open, processing, error, title, text, success } = this.state;
    return (
      <SuperPopup
        modalTitle='Delete account'
        open={open}
        onClose={this.closeModal}
        icon={icWarning}
        title={title}
        text={text}
        buttons={
          success
            ? null
            : [
                { text: 'Cancel', onClick: this.closeModal },
                {
                  text: 'Delete my account',
                  onClick: this.deleteAccount,
                  style: 'dark',
                },
              ]
        }
        processing={processing}
        error={error}
      />
    );
  }
}

export default DeleteAccount;

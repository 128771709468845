import React from 'react';

const SegmentType = ({ typeIconUrl }) => {
  return (
    <div className='flex h-[1.625rem] w-[1.625rem] mr-1 text-body-inverted rounded-full justify-center items-center bg-primary'>
      <img src={typeIconUrl} className='h-4' />
    </div>
  );
};

export default SegmentType;

import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import apiFetch from '../../src/js/fetch';
import Activity from '../../src/js/activities/debug';

class ActivityShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: props.state,
      activity: props.activity,
      segments: [],
      segmentSearch: '',
      segmentId: '',
      loaded: true,

      lat: '',
      lng: '',
    };

    this.initMap = this.initMap.bind(this);
  }

  componentDidMount() {
    const { state, activity } = this.state;

    console.log({ state, activity });

    if (state === 'completed') {
      // init map
      this.initMap(activity);
    } else {
      alert('Activity is not in completed state');
    }
  }

  async initMap(activity) {
    let coords = null;
    if (activity.sample_file) {
      // Fetch the coordinates.
      const d = await apiFetch(activity.sample_file);
      if (d && d.samples) {
        coords = d.samples;
      }
    }

    if (coords) {
      this.activitySamples = coords;

      console.log({ activitySamples: this.activitySamples });

      // init map
      this.mapHandler = new Activity({
        coordinates: this.activitySamples,
        maxZoom: 25,
        polylineOptions: {
          color: '#2C3232',
          weight: 1,
        },
      });

      // Add debug markers for the activity samples
      this.mapHandler.addDebugMarkersToMap(this.activitySamples);
    }
  }

  componentWillUnmount() {
    // stop polling
    console.log('unmounting');
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name === 'segmentSearch') {
      this.searchSegments(value);
    }

    if (name === 'segmentId') {
      this.addSegmentToMap(value);
    }
  };

  searchSegments = async (value) => {
    console.log('searching segments:', value);

    this.setState({ loaded: false });
    try {
      const segments = await apiFetch(`/api/segments/search?search=${value}`);
      console.log({ segments });

      this.setState({ segments });

      // if (segments.length) {
      //     this.mapHandler.addSegmentsToMap(segments);
      //     this.mapHandler.flyTo([segments[0].stop_lat, segments[0].stop_lng])
      // }
    } catch (error) {
      console.log({ error });
    }
    this.setState({ loaded: true });
  };

  addSegmentToMap = async (segmentId) => {
    console.log('add segment to map:', segmentId);

    const segment = this.state.segments.find(
      (s) => s.id === parseInt(segmentId)
    );

    let coords = null;
    if (segment.sample_file) {
      // Fetch the coordinates.
      const d = await apiFetch(segment.sample_file);
      if (d && d.coordinates) {
        coords = d.coordinates;
      }
    }

    if (coords) {
      console.log('Adding segment to map:', segmentId, coords);
      // Add debug markers for the activity samples
      this.mapHandler.addDebugMarkersToMap(coords, 'segment', segment.name);
    }
  };

  addToMap = () => {
    const { lat, lng } = this.state;

    this.mapHandler.addMarker([parseFloat(lat), parseFloat(lng)], {
      color: 'yellow',
    });
    this.mapHandler.flyTo([parseFloat(lat), parseFloat(lng)]);

    this.setState({ lat: '', lng: '' });
  };

  render() {
    const { state, segments, segmentSearch, segmentId, loaded, lat, lng } =
      this.state;

    return (
      <>
        <h2 className='mini-label font-medium'>Activity</h2>
        {state !== 'completed' && (
          <div
            className={`inline-block rounded p-8pt mb-24pt ${
              state === 'completed'
                ? 'bg-primary'
                : state === 'processing'
                ? 'bg-tertiary'
                : 'bg-error'
            }`}
          >
            <p className='mb-0 text-body-inverted'>
              Status:{' '}
              {state === 'error'
                ? `${state} - will try sync again at a later point.`
                : state}
            </p>
          </div>
        )}

        <div>
          <label className='input-label block'>Compare segment</label>
          <input
            className='input mb-4 w-full'
            type='text'
            name='segmentSearch'
            id='segment_name'
            value={segmentSearch}
            onChange={this.onChange}
            placeholder='Search for a segment'
          />

          <select
            className='input mb-4 pr-8'
            name='segmentId'
            value={segmentId}
            onChange={this.onChange}
            id='segment_id'
          >
            <option value=''>Select a segment</option>
            {segments.map((segment, i) => (
              <option key={i} value={segment.id}>
                {segment.name}
              </option>
            ))}
          </select>
          {!loaded && (
            <div className='mb-4'>
              <BarLoader />
            </div>
          )}
        </div>
        <div className='mb-4'>
          <label className='input-label block'>Add custom debug marker</label>
          <input
            className='input mb-4 w-full'
            type='text'
            name='lat'
            id='lat'
            value={lat}
            placeholder='Lat: 55.0000000'
            onChange={this.onChange}
          />
          <input
            className='input mb-4 w-full'
            type='text'
            name='lng'
            id='lng'
            value={lng}
            placeholder='Lng: 10.0000000'
            onChange={this.onChange}
          />
          <button className='button dark' onClick={this.addToMap}>
            Add to map
          </button>
        </div>

        {state === 'completed' ? (
          <>
            <div className='small-body-text mb-4'>
              <p className='mb-1'>Blue dots: Activity coordinates</p>
              <p className='mb-1'>
                Green dots: Calculated coordinates (added to the activity when
                analyzed if device was using "Smart" recording)
              </p>
              <p className='mb-3'>Red dots: Segment coordinates</p>

              <p className='mini-text text-text-grey-600'>
                To measure between points: Click the ruler on the right to
                corner (in the map). You can then click where you want, and
                click another point to see the distance. Double 'escape' stops
                the measurement.
              </p>
            </div>
            <div id='map' className='map'></div>
            {/* <a href={activity.create_segment_url} className="button">Create a segment</a> */}
          </>
        ) : null}

        {state === 'processing' || (state === 'error' && <BarLoader />)}
        {/* {state === 'completed' && activity.segment_efforts.length > 0 &&
                    <div className="p-16pt">
                        <h4>Segment efforts</h4>
                        <ul>
                            {activity.segment_efforts.map(s => {
                                return (
                                    <li key={`segment_effort_${s.id}`}>{s.name}: {s.duration_in_seconds} seconds. <a className="text-text-accent" href={s.url}>View segment</a></li>
                                )
                            })}
                        </ul>
                    </div>
                } */}
      </>
    );
  }
}
export default ActivityShow;

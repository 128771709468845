import React from 'react';

const Splash = ({ children, title, text }) => {
  /** FYI: There is a erb version of this file in templates/partials/_splash.html.erb */
  return (
    <div className='w-full bg-background-quaternary flex flex-col items-center justify-center text-center min-h-[18.75rem] mb-12 rounded-xl border border-border-darker-yellow px-8'>
      {title ? <p className='body-text max-w-[35rem]'>{title}</p> : null}
      {text ? <p className='body-text max-w-[35rem]'>{text}</p> : null}

      {children}
    </div>
  );
};

export default Splash;

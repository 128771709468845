import React, { useState, useCallback } from 'react';
import { point } from '@turf/helpers';
import distance from '@turf/distance';
import coordinatesDistance from '../../src/js/lib/coordinatesDistance';

const Altitude = ({ coordinates, segmentDistance, editor = false, range }) => {
  const height = 120;
  const bottomOffset = 30;
  const [svgWidth, setSvgWidth] = useState(null);
  const [xFactor, setXFactor] = useState(null);
  const [yFactor, setYFactor] = useState(null);
  const [polylinePoints, setPolylinePoints] = useState(null);
  const [maxX, setMaxX] = useState(null);
  const [maxY, setMaxY] = useState(null);
  const [minY, setMinY] = useState(null);

  const containerRef = useCallback((node) => {
    if (node !== null) {
      // When the container is mounted and we have the div ref, get the width of the container and create the polyline.
      const { width } = node.getBoundingClientRect();
      setSvgWidth(width);

      createPolyline(width);
    }
  }, []);

  const createPolyline = (width) => {
    // Filter out the coordinates without altitude.
    const filterCoordinates = coordinates.filter(
      (c) => (c.alt || c.altitude) && !c.manual
    );

    // Calculate the max, min and diff.
    const altitudes = filterCoordinates.map((c) =>
      c.alt ? c.alt : c.altitude
    );

    // Calculate the total distance of the polyline. (can be different from the segment distance, because of the manual points).
    const totalPolylineDistance = coordinatesDistance(filterCoordinates);

    // Calculate the max, min and diff.
    const max = Math.max(...altitudes);
    const min = Math.min(...altitudes);
    const diff = max - min;

    // Calculate the x and y factors. Used to scale the polyline to the svg.
    const xFactor = totalPolylineDistance / width;
    const yFactor = diff / height;
    setXFactor(xFactor);
    setYFactor(yFactor);

    // Calculate the y offset. Used to center the polyline in the svg.
    const yOffset = height - max / yFactor;

    // Needed to calculate the x position of the polyline points.
    let currentDistanceCovered = 0;
    // Needed to calculate the max x position of the polyline points.
    let maxX = 0;
    let minY = 0;
    let maxY = 0;

    // Create the polyline points.
    let polyPoints = filterCoordinates
      .map((c, i) => {
        if (i === 0) {
          // The first point.
          const x = 0;

          // The y position of the point. (Add - to invert the y axis.)
          const y = -(c.alt || c.altitude) / yFactor + yOffset;

          // Set the max x position.
          maxX = x;
          minY = y;
          maxY = y;

          // Return the polyline point.
          return `${x},${y}`;
        } else {
          // The other points.
          const pointA = point([c.lng, c.lat]);
          const pointB = point([
            filterCoordinates[i - 1].lng,
            filterCoordinates[i - 1].lat,
          ]);

          // Calculate the distance between this and the previous point.
          const distanceBetweenThisAndPrevious =
            distance(pointA, pointB) * 1000; // in meters

          // Add the distance to the total distance covered.
          currentDistanceCovered += distanceBetweenThisAndPrevious;

          // Calculate the x and y position of the point.
          const x = currentDistanceCovered / xFactor;
          const y = -(c.alt || c.altitude) / yFactor + yOffset; // Add - to invert the y axis.

          // Set the max x position.
          if (x > maxX) {
            maxX = x;
          }
          if (y < minY) {
            minY = y;
          }
          if (y > maxY) {
            maxY = y;
          }

          // Return the polyline point.
          return `${x},${y}`;
        }
      })
      .join(' ');

    setMaxX(maxX);
    setMinY(minY);
    setMaxY(maxY);
    setPolylinePoints(polyPoints);
  };

  if (!coordinates) {
    return null;
  }

  return (
    <div ref={containerRef} className='w-full'>
      {svgWidth ? (
        <svg
          width='100%'
          height={height + bottomOffset}
          viewBox={`0 ${minY} ${svgWidth} ${height + bottomOffset}`}
        >
          {/* The background (fill). Add a point before and after the polylinePoints as the y-coordinate has to be lowest point in the curve at start and end of graph to fill everything below the polyline. */}
          <polyline
            points={
              `0,${maxY + bottomOffset} ` +
              polylinePoints +
              ` ${maxX},${maxY + bottomOffset}`
            }
            className='fill-background'
          />
          {/* The polyline (stroke) */}
          <polyline
            points={polylinePoints}
            className='stroke-black stroke-2 fill-none'
          />

          {editor && range ? (
            <>
              <defs>
                <linearGradient id='grad-fill'>
                  <stop offset={range[0] + '%'} stopColor='transparent' />
                  <stop offset={range[0] + '%'} stopColor='#576363' />
                  <stop offset={range[1] + '%'} stopColor='#576363' />
                  <stop offset={range[1] + '%'} stopColor='transparent' />
                </linearGradient>
                <linearGradient id='grad-stroke'>
                  <stop offset={range[0] + '%'} stopColor='transparent' />
                  <stop offset={range[0] + '%'} stopColor='#000' />
                  <stop offset={range[1] + '%'} stopColor='#000' />
                  <stop offset={range[1] + '%'} stopColor='transparent' />
                </linearGradient>
              </defs>

              {/* The background (fill). Add a point before and after the polylinePoints as the y-coordinate has to be lowest point in the curve at start and end of graph to fill everything below the polyline. */}
              <polyline
                points={
                  `0,${maxY + bottomOffset} ` +
                  polylinePoints +
                  ` ${maxX},${maxY + bottomOffset}`
                }
                fill='url(#grad-fill)'
                stroke='none'
              />
              {/* The polyline (stroke) */}
              <polyline
                points={polylinePoints}
                stroke='url(#grad-stroke)'
                fill='none'
              />
            </>
          ) : null}
        </svg>
      ) : null}
      <div className='flex justify-between mini-text text-text-grey-500 mt-2'>
        <p className='mb-0'>0km</p>
        <p className='mb-0'>{(segmentDistance / 1000).toFixed(1)}km</p>
      </div>
    </div>
  );
};

export default Altitude;

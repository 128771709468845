import React from 'react';
import location from '../../../images/icons/ic-location-dark.svg';
import coordinates from '../../../images/icons/ic-coordinates.svg';
import elevation from '../../../images/icons/ic-elevation.svg';
import SegmentType from '../../SegmentType';
import { formatDuration } from '../../../src/js/lib/duration';
import { formatPosition } from '../../../src/js/lib/position';

const Item = ({ segment, index }) => (
  <a
    href={segment.url}
    className='block 2xl:flex items-start justify-between w-full p-3 mt-2 segment__item bg-background-quinary 2xl:bg-transparent rounded'
  >
    <div className='flex-1 items-center small-body-text 2xl:flex 2xl:justify-between mb-2 2xl:mb-0'>
      <div className='items-center mb-2 2xl:mb-0 flex'>
        <div className='flex items-center mr-2'>
          <span className='pr-2 mini-label text-text-grey-600 min-w-[2rem]'>
            S{index + 1}
          </span>
          <SegmentType typeIconUrl={segment.sport_icon_url} />
        </div>
        <h3 className='font-medium small-body-text mb-2 ml-1 md:mb-0 md:ml-0 truncate'>
          {segment.name}
        </h3>
      </div>
      <div className='border-border-secondary 2xl:w-[10rem] 2xl:flex justify-center segment__position'>
        <div className='flex'>
          <p className='mb-0'>
            #{' '}
            <span>
              {formatPosition(segment.current_user_segment_effort?.position)}
            </span>
          </p>
          {segment.current_user_segment_effort?.duration ? (
            <p className='mb-0 ml-5'>
              <span>
                {formatDuration(segment.current_user_segment_effort.duration)}
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </div>

    <div className='shrink-0 flex 2xl:justify-end small-body-text 2xl:w-2/5 segment__stats flex-wrap'>
      <div className='flex items-center shrink-0 mr-4 text-text-grey-600'>
        <img src={location} className='w-3 mr-2' />
        <span>{segment.city}</span>
      </div>
      <div className='flex items-center shrink-0 mr-4 text-text-grey-600'>
        <img src={coordinates} className='w-[14px] mr-2' />
        <span>{(segment.distance_in_meters / 1000).toFixed(2)} km</span>
      </div>
      <div className='flex items-center shrink-0 text-text-grey-600'>
        <img src={elevation} className='w-[14px] mr-2' />
        <span>
          {segment.elevation ? parseFloat(segment.elevation).toFixed(0) : '00'}m
        </span>
      </div>
    </div>
  </a>
);

export default Item;

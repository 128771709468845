import React from 'react';
import apiFetch from '../../src/js/fetch';
import ContentContainer from '../ContentContainer';
import editIcon from '../../images/icons/ic-edit.svg';
import BarLoader from 'react-spinners/BarLoader';

class CreateSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      name: props.name || '',
      description: props.description || '',
      id: props.id,

      loading: false,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, description, id } = this.state;
    if (!name) {
      console.log('Missing data:', { name });
      this.setState({
        loading: false,
        error: 'You need to fill in a name.',
      });
      return;
    }

    this.setState({ loading: true });

    console.log('Submit form');
    const data = {
      segment: {
        name: name,
        description: description,
      },
    };

    try {
      const updated = await apiFetch('/api/segments/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      window.location.href = updated.url; // "/segments/" + created.id;
    } catch (e) {
      console.log({ e });
      const err = await e.json();
      console.log({ err });
      this.setState({
        loading: false,
        error:
          err.error_message || 'Something went wrong. Please try again later.',
      });
    }
  };

  render() {
    const { loading, name, description, error } = this.state;

    return (
      <section className='pt-8'>
        <form className='' method='post' onSubmit={this.handleSubmit}>
          <div className='relative w-full lg:max-w-[53rem]'>
            <textarea
              name='name'
              id='name'
              autoFocus
              className={`placeholder:text-heading-md placeholder:font-heading text-heading-xs leading-[2.2] mb-6 pr-6 align-middle bg-transparent appearance-none max-w-full outline-none border-none h-20 w-full ${
                error && !name ? 'border-b border-error' : 'border-border'
              }`}
              required={true}
              onChange={this.handleChange}
              value={name}
              placeholder='Segment name'
              disabled={loading}
            />
            <img
              src={editIcon}
              className='absolute right-0 my-auto top-0 bottom-0 mr-2 cursor-text pointer-events-none'
            />
          </div>
          <div className='w-full lg:max-w-[53rem] mb-8'>
            <ContentContainer>
              <h2 className='mini-label font-medium'>
                Additional information (optional)
              </h2>
              <textarea
                name='description'
                id='description'
                className='w-full'
                placeholder='An epic description.'
                required={false}
                onChange={this.handleChange}
                value={description}
                disabled={loading}
              ></textarea>
            </ContentContainer>
          </div>
          {loading && (
            <div className='mb-8 w-full'>
              <BarLoader loading={loading} />
            </div>
          )}
          <button type='submit' className='button dark mb-4'>
            Confirm and update segment
          </button>

          {error && <p className='text-text-grey-600 max-w-[35rem]'>{error}</p>}
        </form>
      </section>
    );
  }
}
export default CreateSegment;

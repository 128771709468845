import React from 'react';
import SuperPopup from '../../SuperPopup';
import icon from '../../../images/icons/ic-sports.svg';
// import Turbolinks from 'turbolinks';

class Celebration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  closeModal = () => {
    this.setState({ open: false });
    // remove checkout parameters from URL
    const url = new URL(window.location.href);
    url.searchParams.delete('checkout');

    window.Turbo.visit(url, { action: 'replace' });
  };

  render() {
    const { open } = this.state;
    return (
      <SuperPopup
        open={open}
        onClose={this.closeModal}
        icon={icon}
        title='Thank you!'
        text='Your subscription has been successfully created. You now have Full access to HurryUp.'
        buttons={[{ text: 'Close', onClick: this.closeModal, style: 'dark' }]}
      />
    );
  }
}

export default Celebration;

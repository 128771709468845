import React from 'react';

function IcFilter() {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.66667 11.8332C9.02222 11.8332 8.47222 11.6054 8.01667 11.1498C7.56111 10.6943 7.33333 10.1443 7.33333 9.49984C7.33333 8.85539 7.56111 8.30539 8.01667 7.84984C8.47222 7.39428 9.02222 7.1665 9.66667 7.1665C10.3111 7.1665 10.8611 7.39428 11.3167 7.84984C11.7722 8.30539 12 8.85539 12 9.49984C12 10.1443 11.7722 10.6943 11.3167 11.1498C10.8611 11.6054 10.3111 11.8332 9.66667 11.8332ZM9.66275 10.8332C10.032 10.8332 10.3472 10.7039 10.6083 10.4454C10.8694 10.1869 11 9.87304 11 9.50375C11 9.13448 10.8708 8.81928 10.6123 8.55817C10.3538 8.29706 10.0399 8.1665 9.67058 8.1665C9.30131 8.1665 8.98611 8.29575 8.725 8.55425C8.46389 8.81274 8.33333 9.12663 8.33333 9.49592C8.33333 9.8652 8.46258 10.1804 8.72108 10.4415C8.97957 10.7026 9.29346 10.8332 9.66275 10.8332ZM0 9.99984V8.99984H6V9.99984H0ZM2.33333 4.83317C1.68889 4.83317 1.13889 4.60539 0.683333 4.14984C0.227778 3.69428 0 3.14428 0 2.49984C0 1.85539 0.227778 1.30539 0.683333 0.849837C1.13889 0.394282 1.68889 0.166504 2.33333 0.166504C2.97778 0.166504 3.52778 0.394282 3.98333 0.849837C4.43889 1.30539 4.66667 1.85539 4.66667 2.49984C4.66667 3.14428 4.43889 3.69428 3.98333 4.14984C3.52778 4.60539 2.97778 4.83317 2.33333 4.83317ZM2.32942 3.83317C2.69869 3.83317 3.01389 3.70392 3.275 3.44542C3.53611 3.18693 3.66667 2.87304 3.66667 2.50375C3.66667 2.13448 3.53742 1.81928 3.27892 1.55817C3.02043 1.29706 2.70654 1.1665 2.33725 1.1665C1.96797 1.1665 1.65278 1.29575 1.39167 1.55425C1.13056 1.81274 1 2.12663 1 2.49592C1 2.8652 1.12925 3.18039 1.38775 3.4415C1.64624 3.70262 1.96013 3.83317 2.32942 3.83317ZM6 2.99984V1.99984H12V2.99984H6Z'
        fill='#2C3232'
      />
    </svg>
  );
}

export default IcFilter;

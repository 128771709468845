import React, { useEffect, useRef } from 'react';
import icClose from '../images/icons/ic-exit.svg';
import focusLock from 'dom-focus-lock';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function Modal({
  children,
  title,
  open,
  onClose,
  contentContainerClasses = 'w-full',
  modalWidth = 'max-w-md w-full',
  zIndex = 'z-50 md:z-[1000] xl:z-50',
}) {
  const modalRef = useRef(null);
  useEffect(() => {
    if (open) {
      // Disabling and enabling scroll on body on modal open and close. Also, locking focus inside modal.
      disableBodyScroll(modalRef.current);
      focusLock.on(modalRef.current);
    }
  }, [open]);

  useEffect(() => {
    // Switch off scroll lock when component unmounts
    return () => {
      if (modalRef.current) {
        enableBodyScroll(modalRef.current);
        focusLock.off(modalRef.current);
      }
    };
  }, []);

  if (!open) return null;

  const onModalClick = (e) => {
    if (e.target.classList.contains('report-modal')) {
      handleClose();
    }
  };

  const handleClose = () => {
    enableBodyScroll(modalRef.current);
    focusLock.off(modalRef.current);

    onClose();
  };

  return (
    <div
      ref={modalRef}
      className={`${zIndex} fixed top-0 left-0 bottom-0 right-0 bg-background-secondary/60 p-16pt report-modal overflow-y-auto flex`}
      aria-modal='true'
      role='dialog'
      aria-labelledby='modal-title'
      onClick={onModalClick}
    >
      <div
        className={`bg-background-quaternary p-16pt ${modalWidth} text-text-body rounded-lg m-auto`}
      >
        <div className='flex justify-between items-start mb-8'>
          <div>
            {title ? (
              <h2 id='modal-title' className='mini-label font-medium m-0'>
                {title}
              </h2>
            ) : null}
          </div>
          <button
            aria-label='Close modal'
            className='ml-4 h-auto small-link-text text-text-grey-600 align-middle appearance-none'
            onClick={handleClose}
          >
            <span>Close</span>
            <img className='inline-block ml-2' src={icClose} />
          </button>
        </div>
        <div className={contentContainerClasses}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;

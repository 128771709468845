import React from 'react';
import apiFetch from '../../src/js/fetch';
import CreateSegmentHandler from '../../src/js/segments/new';
import ContentContainer from '../ContentContainer';
import editIcon from '../../images/icons/ic-edit.svg';
import toolTip from '../../images/icons/ic-info.svg';
import lineChunk from '@turf/line-chunk';
import { uploadFile } from '../../src/js/lib/directUpload';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import BarLoader from 'react-spinners/BarLoader';
import Altitude from './Altitude';

class CreateSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      name: '',
      description: '',
      errors: {},
      segmentTypeId: '',

      // activity
      activityId: this.props.activity_id,
      activity: null,

      loading: true,

      // selection
      selectionDistance: null,
      selectionRange: [0, 100],
      validLength: true,
    };

    this.mapHandler = null;

    this.openedFromCreateChallenge =
      new URLSearchParams(document.location.search).get('source') ===
      'create-challenge';

    this.fetchCoordinates = this.fetchCoordinates.bind(this);
    this.fetchActivities = this.fetchActivities.bind(this);
    this.handleActivityChange = this.handleActivityChange.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  componentDidMount() {
    if (this.state.activityId) {
      // A activity_id is passed, so we can fetch the coordinates
      this.fetchCoordinates(this.state.activityId);
    } else {
      this.fetchActivities();
    }
  }
  componentWillUnmount() {
    // Remove the map
    console.log('unmounting');
    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }
  fetchActivities = async () => {
    console.log('fetchActivities');
    try {
      const data = await apiFetch(`/api/activities`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log({ data });

      this.setState({ activities: data, loading: false });
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    }
  };
  fetchCoordinates = async (activityId) => {
    console.log('fetchCoordinates');
    try {
      const data = await apiFetch(
        `/api/activities/coordinates?id=${activityId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );
      console.log({ data });

      this.setState({ activity: data.activity, loading: false }, async () => {
        // Download json file if sample_file is present
        let coords = null;
        if (data.sample_file) {
          const d = await apiFetch(data.sample_file);
          if (d && d.samples) {
            coords = d.samples;
          }
          console.log({ coords: coords });
        }

        if (coords && coords.length) {
          // Create the map
          this.setState({ coordinates: coords });

          this.mapHandler = new CreateSegmentHandler({
            coordinates: coords,
            selectControlEnabled: true,
            selectionCallback: this.handleSelection,
          });
        } else {
          alert('No coordinates found for this activity.');
          this.setState({ loading: false, activity: null });
        }
      });
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false });
    }
  };

  handleSelection = (selection) => {
    console.log({ selection });

    const validLength =
      selection?.distance >= 150 && selection?.distance < 50000; // Max 50km

    // Update the selection data, and set new range of the slider
    this.setState({
      selectionDistance: selection?.distance,
      selectionRange: selection.range,
      error: null,
      validLength: validLength,
    });
  };

  onChangeSelectionSlider = (value) => {
    console.log({ value });
    this.setState({ selectionRange: value });

    // Update the selection in the map.
    if (this.mapHandler) {
      this.mapHandler.setSelection(value);
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, description, validLength, selectionDistance } = this.state;
    const selection = this.mapHandler && this.mapHandler.getSelection();
    let coordinates = [];

    if (!selection || !selection.startPoint || !selection.stopPoint || !name) {
      this.setState({
        loading: false,
        error:
          'You need to fill in all fields, and select a course by dragging the start and stop along your activity.',
      });
      return;
    }

    if (!validLength) {
      // The segment length is too long/short. The maximum length is 50km, minimum 150 meters.
      this.setState({
        loading: false,
        error:
          selectionDistance < 150
            ? 'The segment is too short. The minimum is 150m.'
            : 'The segment is too long. The maximum distance is 50km.',
      });
      return;
    } else {
      // Distance is valid, but we need to prepare the coordinates, to make sure they are not too spread out (too long distance between the points (max 10 meters)).
      this.setState({ error: null, loading: true });

      console.log({ Selection: selection, time: new Date().getTime() });
      // make sure a line is not more than 10 meters. Linechunk will chop it up if it is
      // Chops each feature into points max 10 meters apart if originaly longer distance
      const geoJson = lineChunk(this.mapHandler.getSelectionGeoJson(), 0.01, {
        units: 'kilometers',
      });
      console.log({ geoJson, time: new Date().getTime() });

      // Get all coordinates from the geoJson
      coordinates = geoJson.features
        .map((obj) => L.GeoJSON.coordsToLatLngs(obj.geometry.coordinates))
        .flat(1);

      console.log({ coordinates, time: new Date().getTime() });
    }

    this.setState({ loading: true });

    // Upload the coordinates directly
    const body = JSON.stringify(coordinates);
    const blob = new Blob([body], {
      type: 'application/json',
    });

    // Create a file from the compressed blob
    const coordinatesJsonFile = new File([blob], 'coordinates.json', {
      type: blob.type,
    });

    // console.log({
    //   bodyLength: body.length,
    //   compressedLength: blob.length,
    //   body,
    //   blob,
    //   coordinatesJsonFile,
    // });

    const uploaded = await uploadFile(coordinatesJsonFile);
    // console.log({ uploaded });

    const signedId = uploaded.signed_id;

    // Send the data and signed id to the server
    const data = {
      segment: {
        name: name,
        description: description,
        distance_in_meters: selection.distance,
        coordinates: coordinates,
        start_lat: selection.startPoint.lat,
        start_lng: selection.startPoint.lng,
        stop_lat: selection.stopPoint.lat,
        stop_lng: selection.stopPoint.lng,
        activity_id: this.state.activityId,
        temp_coordinates_file: signedId,
      },
    };

    try {
      const created = await apiFetch('/api/segments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      if (this.openedFromCreateChallenge) {
        // Close the tab, instead of redirecting. The user was directed here in a new tab while creating a challenge.
        window.close();
        return;
      }

      window.location.href = created.url; // "/segments/" + created.id;
    } catch (e) {
      console.log({ e });
      const err = await e.json();
      console.log({ err });
      this.setState({
        loading: false,
        error:
          err.error_message || 'Something went wrong. Please try again later.',
      });
    }
  };

  handleActivityChange = (event) => {
    this.setState({
      activityId: event.target.value,
      loading: true,
      error: null,
    });
    this.fetchCoordinates(event.target.value);
  };
  render() {
    const {
      activities = [],
      activity,
      activityId,
      loading,
      name,
      description,
      selectionDistance,
      error,
      selectionRange,
      validLength,
      coordinates,
    } = this.state;

    console.log({ activity, coordinates });

    return (
      <section className='pt-8'>
        <form
          id='create-segment-form'
          className=''
          method='post'
          onSubmit={this.handleSubmit}
        >
          <div className='relative w-full lg:max-w-[53rem]'>
            <textarea
              name='name'
              id='name'
              autoFocus
              className={`placeholder:text-heading-md placeholder:font-heading text-heading-xs leading-[2.2] mb-2 pr-6 align-middle bg-transparent appearance-none max-w-full outline-none border-none h-20 w-full ${
                error && !name ? 'border-b border-error' : 'border-border'
              }`}
              required={true}
              onChange={this.handleChange}
              value={name}
              placeholder='Segment name'
            />
            <img
              src={editIcon}
              className='absolute right-0 my-auto top-0 bottom-0 mr-2 cursor-text pointer-events-none'
            />
          </div>
          <p className='mb-8 small-body-text'>
            Any segment has to be created from an activity of yours.
          </p>

          <div className='mb-16pt'>
            {activity ? (
              <div className='w-full lg:max-w-[53rem] grid gap-4'>
                <ContentContainer>
                  <h2 className='mini-label font-medium'>
                    Adjust your segment
                  </h2>
                  <div id='map' className='map'></div>
                  <div className='mt-4'>
                    {!validLength && (
                      <div className='w-full flex justify-between items-start px-16pt py-12pt rounded-button notification style-warning mb-4'>
                        <p>
                          {selectionDistance < 150
                            ? 'The segment is too short. The minimum is 150m.'
                            : 'The segment is too long. The maximum distance is 50km.'}
                        </p>
                      </div>
                    )}
                    <div className='flex items-center flex-wrap justify-between mb-4'>
                      <div className='order-1 w-full sm:w-auto'>
                        <p className='mini-text font-semibold mb-2'>
                          Adjust start-point
                        </p>
                        <div className='flex'>
                          <button
                            type='button'
                            className='button small bordered mr-1 w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection(
                                'start',
                                'backward-big'
                              )
                            }
                          >
                            -10
                          </button>
                          <button
                            type='button'
                            className='button small bordered mr-1 w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection(
                                'start',
                                'backward'
                              )
                            }
                          >
                            -1
                          </button>
                          <button
                            type='button'
                            className='button small bordered mr-1 w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection('start', 'forward')
                            }
                          >
                            +1
                          </button>
                          <button
                            type='button'
                            className='button small bordered w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection(
                                'start',
                                'forward-big'
                              )
                            }
                          >
                            +10
                          </button>
                        </div>
                      </div>
                      <div className='flex-1 lg:mx-4 mt-8 lg:mt-6 flex items-center order-3 lg:order-2 w-full min-w-[80%] lg:min-w-0'>
                        <Slider
                          allowCross={false}
                          onChange={this.onChangeSelectionSlider}
                          value={selectionRange}
                          range
                          railStyle={{ backgroundColor: '#E6F0F0' }}
                          trackStyle={{ backgroundColor: '#2C3232' }}
                          handleStyle={{
                            backgroundColor: '#2C3232',
                            border: 'none',
                          }}
                        />
                      </div>
                      <div className='order-2 lg:order-3 w-full sm:w-auto mt-4 sm:mt-0'>
                        <p className='mini-text font-semibold mb-2 w-full sm:text-right'>
                          Adjust end-point
                        </p>
                        <div className='flex sm:justify-end'>
                          <button
                            type='button'
                            className='button small bordered mr-1 w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection(
                                'end',
                                'backward-big'
                              )
                            }
                          >
                            -10
                          </button>
                          <button
                            type='button'
                            className='button small bordered mr-1 w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection('end', 'backward')
                            }
                          >
                            -1
                          </button>
                          <button
                            type='button'
                            className='button small bordered mr-1 w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection('end', 'forward')
                            }
                          >
                            +1
                          </button>
                          <button
                            type='button'
                            className='button small bordered w-10'
                            disabled={!selectionDistance}
                            onClick={() =>
                              this.mapHandler.nudgeSelection(
                                'end',
                                'forward-big'
                              )
                            }
                          >
                            +10
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-2 mb-6'>
                      <img src={toolTip} className='' />
                      <a
                        className='text-text-grey-500 hover:text-text-grey-600'
                        href='/how-to-create-good-segments'
                        target='_blank'
                      >
                        How to create good segments
                      </a>
                    </div>
                    <p className='small-body-text'>
                      <span className='font-medium'>Distance:</span>{' '}
                      <span id='segment-length'>
                        {selectionDistance
                          ? (selectionDistance / 1000).toFixed(2) + 'km'
                          : 'Drag the slider, or set a start and end-point in the map.'}
                      </span>
                    </p>
                  </div>

                  {coordinates && coordinates.length > 0 ? (
                    <div className='mt-8 lg:mt-4'>
                      <Altitude
                        coordinates={coordinates}
                        segmentDistance={activity.distance_in_meters}
                        editor={true}
                        range={selectionRange}
                      />
                    </div>
                  ) : null}
                </ContentContainer>
                <ContentContainer>
                  <h2 className='mini-label font-medium'>
                    Additional information (optional)
                  </h2>
                  <textarea
                    name='description'
                    id='description'
                    className='w-full'
                    placeholder='An epic description.'
                    required={false}
                    onChange={this.handleChange}
                    value={description}
                  ></textarea>
                </ContentContainer>
              </div>
            ) : activityId ? null : (
              <div className='w-full lg:max-w-[53rem]'>
                <ContentContainer>
                  <h2 className='mini-label font-medium'>Activity</h2>
                  <select
                    className='border border-borders w-full mb-4'
                    onChange={this.handleActivityChange}
                  >
                    <option value=''>Select an activity</option>
                    {activities.map((activity) => (
                      <option
                        key={`activity-${activity.id}`}
                        value={activity.id}
                      >
                        {activity.name}
                      </option>
                    ))}
                  </select>
                  <a href='/activities/new' className='underline'>
                    Import manual activity
                  </a>
                </ContentContainer>
              </div>
            )}
            {loading ? (
              <div className='my-4'>
                <BarLoader />
              </div>
            ) : null}
          </div>
          <button type='submit' className='button dark mb-4'>
            Confirm and create segment
          </button>
          {this.openedFromCreateChallenge ? (
            <p className='small-body-text mb-4'>
              The tab will be closed when you click confirm, so you can continue
              creating your challenge.
            </p>
          ) : null}
          {error && <p className='text-text-grey-600 max-w-[35rem]'>{error}</p>}
        </form>
      </section>
    );
  }
}
export default CreateSegment;

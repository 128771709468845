import React from 'react';
import SuperPopup from '../SuperPopup';
import icWarning from '../../images/icons/ic-warning.svg';
import apiFetch from '../../src/js/fetch';

class PolarDeauthorize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      processing: false,
      error: null,
    };
  }

  openModal = (e) => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    if (this.state.processing) return;
    this.setState({ open: false });
  };

  deauthorize = async () => {
    this.setState({ processing: true });

    try {
      const deleted = await apiFetch('/api/services/polar-deauthorize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      location.reload();
    } catch (error) {
      console.log('Error deauthorize:', error);
      this.setState({ error: 'Something went wrong. Please try again later.' });
    }
    this.setState({ processing: false });
  };

  render() {
    const { open, processing, error } = this.state;
    return (
      <>
        <button onClick={this.openModal} className='button'>
          Disconnect
        </button>

        <SuperPopup
          modalTitle='Disconnect'
          open={open}
          onClose={this.closeModal}
          icon={icWarning}
          title='Are you sure you want to disconnect your Polar device?'
          text='We will no longer be notified when you finish an activity with your Polar device, and you will no longer be able to sync your activities with us. You can always reconnect your Polar device later. The activities you have already synced will not be deleted.'
          buttons={[
            { text: 'Cancel', onClick: this.closeModal },
            {
              text: 'Deauthorize Polar',
              onClick: this.deauthorize,
              style: 'dark',
            },
          ]}
          processing={processing}
          error={error}
        />
      </>
    );
  }
}

export default PolarDeauthorize;

import React, { useState, useEffect } from 'react';
import SuperPopup from '../../SuperPopup';
import TrialEnded from '.';
import Cookies from 'js-cookie';
import icWarning from '../../../images/icons/ic-warning.svg';

/**
 * Displays the Subscribe component in a modal (SuperPopup).
 *
 * The component will open automatically if the user has a trial subscription that has ended.
 */
const types = {
  trial_ended: {
    title: 'Your free trial has ended',
    icon: icWarning,
    text: (
      <span>
        Subscribe to a paid plan to continue with{' '}
        <a href='/pricing' className='underline' target='_blank'>
          Full access
        </a>
        .
      </span>
    ),
    modalTitle: 'Subscribe',
  },
  subscribe: {
    title: 'Subscribe',
    icon: icWarning,
    text: 'Subscribe to get full access to HurryUp.',
    modalTitle: 'Subscribe',
    // trigger: '#subscribe'
    alwaysOpen: true,
    redirectTo: '/profile/settings',
  },
};
function SubscribeModal({ type }) {
  console.log({ type });
  const [open, setOpen] = useState(false);
  const [allowClose, setAllowClose] = useState(true);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const paddleTransaction = queryParameters.get('_ptxn');

    const cookie = Cookies.get(`closed_${type}_modal`);

    if (types[type].trigger) {
      // Show the modal if the trigger is present and clicked
      const trigger = document.querySelector(types[type].trigger);
      if (trigger) {
        trigger.addEventListener('click', () => {
          setOpen(true);
        });
      }
    } else if (types[type].alwaysOpen) {
      setOpen(true);
    } else if (!cookie && !paddleTransaction) {
      // Don't open if cookie is set or if we have a paddle transaction parameter (we're in the checkout flow)
      setOpen(true);
    }
  }, []);

  const onClose = () => {
    if (!allowClose) {
      return true;
    }

    if (types[type].redirectTo) {
      window.location.href = types[type].redirectTo;
    }

    const expire = new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 30); // 30 days
    Cookies.set(`closed_${type}_modal`, true, { expires: expire });
    setOpen(false);
  };

  return (
    <SuperPopup
      title={types[type].title}
      icon={types[type].icon}
      text={types[type].text}
      modalTitle={types[type].modalTitle}
      open={open}
      onClose={onClose}
      zIndex='z-[1000]'
    >
      <TrialEnded setAllowClose={setAllowClose} />
    </SuperPopup>
  );
}

export default SubscribeModal;

import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import apiFetch from '../../../src/js/fetch';
import Segment from '../../../src/js/segments/show';

class SegmentMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sample_file: props.sample_file,
    };
  }

  componentDidMount() {
    this.initMap();
  }

  async initMap() {
    const { sample_file } = this.state;

    let coords = null;
    if (sample_file) {
      // Fetch the coordinates.
      const d = await apiFetch(sample_file);
      if (d && d.coordinates) {
        coords = d.coordinates.map((c) => L.latLng(c.lat, c.lng, c.alt));
      }
    }

    if (coords) {
      // init map
      this.mapHandler = new Segment({
        coordinates: coords,
        mapSelector: 'contest-map',
        seeCurrentLocation: true,
      });
    }
  }

  componentWillUnmount() {
    // stop polling
    console.log('unmounting');

    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  render() {
    const { state, activity } = this.state;
    console.log(activity);
    return (
      <div
        className='h-[70vh] w-full xl:h-auto xl:flex-1 rounded map'
        id='contest-map'
      ></div>
    );
  }
}
export default SegmentMap;

import L from 'leaflet';
import MapHandler from '../mapHandler';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet.routing.icons.png';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import chunk from '../lib/chunk';
import lineChunk from '@turf/line-chunk';

export default class CreateSegments extends MapHandler {
  constructor(options = {}) {
    super(options);

    this.setup = this.setup.bind(this);

    this.createButton = this.createButton.bind(this);
    this.populateAltitude = this.populateAltitude.bind(this);
    this.customSegmentCallback = options.customSegmentCallback;
    this.getRoutingSelection = this.getRoutingSelection.bind(this);
  }

  /*
   * =========================================================
   * Getters and setters
   */

  getRoutingSelection() {
    return this.routingSelection;
  }

  /*
   * =========================================================
   * Routing
   */
  setup() {
    // Adds tile to map
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap',
    }).addTo(this.map);

    console.log('setup admin!', this.map);

    const self = this;

    this.routingControl = L.Routing.control({
      // waypoints: [L.latLng(57.74, 11.94), L.latLng(57.6792, 11.949)],
      router: L.Routing.mapbox(
        'pk.eyJ1IjoiaHVycnl1cGNoYWxsZW5nZXMiLCJhIjoiY2xydjU3cG00MG5oejJqbXI1amx6bmMwMSJ9.93VmTVhQQsXXFq_7SN1aFw'
      ),
      // your other options go here
      routeWhileDragging: false,
      show: false,
    })
      .on('routeselected', function (e) {
        var route = e.route;
        console.log({ e, route });

        let geoJson = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: route.coordinates.map((c) => [c.lng, c.lat]),
          },
        };

        // make sure a line is not more than 10 meters. Linechunk will chop it up if it is
        geoJson = lineChunk(geoJson, 0.01, {
          units: 'kilometers',
        }); // Chops each feature into points max 10 meters apart if originaly longer distance

        // Get all coordinates from the geoJson
        const coordinates = geoJson.features
          .map((obj) => L.GeoJSON.coordsToLatLngs(obj.geometry.coordinates))
          .flat(1);

        self.populateAltitude(coordinates);
      })
      .addTo(this.map);

    this.map.on('click', function (e) {
      self.container = L.DomUtil.create('div');
      self.startBtn = self.createButton(
        'Start from this location',
        self.container
      );
      self.destBtn = self.createButton('Go to this location', self.container);

      L.popup().setContent(self.container).setLatLng(e.latlng).openOn(self.map);

      L.DomEvent.on(self.startBtn, 'click', function () {
        self.routingControl.spliceWaypoints(0, 1, e.latlng);
        self.map.closePopup();
      });

      L.DomEvent.on(self.destBtn, 'click', function () {
        self.routingControl.spliceWaypoints(
          self.routingControl.getWaypoints().length - 1,
          1,
          e.latlng
        );
        self.map.closePopup();
      });
    });
  }

  async populateAltitude(coordinates) {
    try {
      const chunks = chunk(coordinates, 300); // Google API limit is 512, but it failed a lot when using 512, so setting it to 300.
      const chunked = [];
      for (const ch of chunks) {
        chunked.push(
          encodeURIComponent(ch.map((c) => `${c.lat},${c.lng}`).join('|'))
        );
      }
      console.log({ chunked });

      const response = await fetch(`/api/segments/elevation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chunks: chunked,
        }),
      });
      const json = await response.json();
      console.log({ json });

      const coords = json.coordinates
        .flat(1)
        .map((c, i) => L.latLng(c.location.lat, c.location.lng, c.elevation));
      console.log({ coords });

      this.routingSelection = {
        startPoint: coords[0],
        stopPoint: coords[coords.length - 1],
        distance: parseInt(L.GeometryUtil.length(coords).toFixed(0)),
        coordinates: coords,
      };

      this.customSegmentCallback(this.routingSelection);
    } catch (error) {
      alert('Error fetching altitude data');
    }
  }

  /*
   * =========================================================
   * Map UI
   */
  createButton(label, container) {
    var btn = L.DomUtil.create('button', '', container);
    btn.setAttribute('type', 'button');
    btn.classList.add('block', 'w-full', 'border', 'p-2', 'm-2', 'rounded');
    btn.innerHTML = label;
    return btn;
  }
}

import React from 'react';
import SuperPopup from '../SuperPopup';
import icon from '../../images/icons/ic-sports.svg';
import apiFetch from '../../src/js/fetch';
import { event } from '../../src/js/lib/plausible';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      contestId: props.contest_id,
    };
  }

  componentDidMount() {
    const signUpTrigger = document.querySelector('#join');
    this.openTriggerClickListener = signUpTrigger.addEventListener(
      'click',
      this.join
    );
  }

  join = async (e) => {
    const { contestId, processing } = this.state;

    if (processing) return;
    e.target.disabled = true;

    this.setState({ processing: true });

    try {
      await apiFetch('/api/join-contest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contestId: contestId }),
      });

      // Track plausible event.
      event('Challenge - joined', {
        challenge: this.props.challenge,
      });

      // Open the modal.
      this.openModal();
    } catch (error) {
      console.log('Error joining:', error);
      alert('Something went wrong. Please try again later.');
    }
    this.setState({ processing: false });

    e.target.disabled = false;
  };

  openModal = (e) => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({ open: false });

    // Global function to reload the leaderboard.
    if (window.reloadLeaderboard) {
      window.reloadLeaderboard();
    }

    // Remove the join button and show the joined result element.
    this.joinTrigger = document.querySelector('#join');
    this.joinResultElement = document.querySelector('#joined-result');
    if (this.joinTrigger) {
      this.joinTrigger.remove();
    }
    if (this.joinResultElement) {
      this.joinResultElement.classList.remove('hidden');
    }
  };

  inviteFriends = () => {
    // Close the modal.
    this.closeModal();

    // Open the invite friends modal.
    this.openInviteTrigger = document.querySelector('#open-invite');
    if (this.openInviteTrigger) {
      this.setState({ open: false }, () => {
        // This page has a button that the user can click to open the invite modal. Trigger a click on it.

        this.openInviteTrigger.click();
      });
    }
  };

  render() {
    const { open } = this.state;
    return (
      <SuperPopup
        open={open}
        onClose={this.closeModal}
        icon={icon}
        title="Great, you're in!"
        text="If you've already completed any of the segments within the challenge timeframe, we'll add your efforts to the leaderboard. You're still able to set a new best time within the challenge timeframe. Good luck!"
        buttons={[
          {
            text: 'Invite friends',
            onClick: this.inviteFriends,
            style: 'normal',
          },
          { text: 'Close', onClick: this.closeModal, style: 'dark' },
        ]}
      />
    );
  }
}

export default SignUp;

export const formatPosition = (position) => {
  if (!position) return '-';

  let postFix = 'th';
  if (position === 1) postFix = 'st';
  if (position === 2) postFix = 'nd';
  if (position === 3) postFix = 'rd';

  return `${position}${postFix}`;
};

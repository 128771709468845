import FitParser from 'fit-file-parser';

class FitRead {
  constructor(file, callback) {
    this.read = this.read.bind(this);
    this.loadedFileData = this.loadedFileData.bind(this);

    this.callback = callback;
    this.read(file);
  }

  read(file) {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      console.log({ res: event.target.result });

      this.loadedFileData(event.target.result);
    });

    reader.readAsArrayBuffer(file);
  }
  loadedFileData(buffer) {
    const self = this;
    const fitParser = new FitParser({
      force: true,
      speedUnit: 'km/h',
      lengthUnit: 'km',
      temperatureUnit: 'celsius',
      elapsedRecordField: true,
      mode: 'list',
    });

    // Parse your file
    fitParser.parse(buffer, function (error, data) {
      if (error) {
        console.log({ error });
      } else {
        console.log({ fit: data });

        let coordinates = [];
        // Run through all the laps and records and extract the coordinates including altitude
        // for (var lap of data.activity.sessions[0].laps) {
        for (var rec of data.records) {
          if (rec.position_lat && rec.position_long) {
            coordinates.push({
              lat: rec.position_lat,
              lng: rec.position_long,
              alt: rec.altitude ? rec.altitude * 1000 : undefined,
            });
          } else {
            console.log({ missingData: rec });
          }
        }
        // }

        // Return the callback to the parent class
        self.callback(coordinates, data);
      }
    });
  }
}
export default FitRead;

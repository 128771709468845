import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import apiFetch from '../../src/js/fetch';
import Activity from '../../src/js/activities/show';

class ActivityShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: props.state,
      activity: props.activity,
    };

    this.poll = this.poll.bind(this);
    this.initMap = this.initMap.bind(this);
  }

  componentDidMount() {
    const { state, activity } = this.state;

    console.log({ state, activity });

    if (state === 'completed') {
      // init map
      this.initMap(activity);
    } else if (state === 'failed') {
      // do nothing
    } else {
      // poll for activity state
      this.pollTimeout = setTimeout(this.poll, 2000);
    }
  }

  async initMap(activity) {
    let coords = null;
    if (activity.sample_file) {
      // Fetch the coordinates.
      const d = await apiFetch(activity.sample_file);
      if (d && d.samples) {
        coords = d.samples;
      }
    }

    if (coords) {
      // init map
      new Activity({
        coordinates: coords,
      });
    }
  }

  async poll() {
    const { activity } = this.state;
    try {
      const data = await apiFetch(`/api/activities/${activity.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log({ data });

      if (data.state === 'completed') {
        // Sync completed

        location.reload(); // Reload the page to get all the information

        // this.setState({ state: data.state, activity: data.activity });

        // // init map
        // this.initMap(data.activity);
      } else if (data.state === 'error') {
        // Sync failed, but will be retried. Continue polling, but with a longer delay
        this.setState({ state: data.state, activity: data.activity });

        // poll again
        this.pollTimeout = setTimeout(this.poll, 8000);
      } else if (data.state === 'failed') {
        // All retries failed. Stop polling

        this.setState({ state: data.state, activity: data.activity });
      } else if (data.state === 'duplicate') {
        // Activity already exists. Stop polling, and reload the page

        location.reload(); // Reload the page to get all the information
      } else {
        // Sync is still in progress. Continue polling

        this.pollTimeout = setTimeout(this.poll, 2000);
      }
    } catch (error) {
      console.log({ error });

      // poll again
      this.pollTimeout = setTimeout(this.poll, 2000);
    }
  }

  componentWillUnmount() {
    // stop polling
    console.log('unmounting');
    clearTimeout(this.pollTimeout);
  }

  render() {
    const { state, activity } = this.state;
    console.log(activity);
    return (
      <>
        {state !== 'completed' && (
          <div
            className={`inline-block rounded p-8pt mb-24pt ${
              state === 'completed'
                ? 'bg-primary'
                : state === 'processing'
                ? 'bg-tertiary'
                : 'bg-error'
            }`}
          >
            <p className='mb-0 text-body-inverted'>
              Status:{' '}
              {state === 'error'
                ? `${state} - will try sync again at a later point.`
                : state}
            </p>
          </div>
        )}

        {state === 'completed' ? (
          <>
            <div id='map' className='map'></div>
            {/* <a href={activity.create_segment_url} className="button">Create a segment</a> */}
          </>
        ) : null}

        {state === 'processing' || (state === 'error' && <BarLoader />)}
        {/* {state === 'completed' && activity.segment_efforts.length > 0 &&
                    <div className="p-16pt">
                        <h4>Segment efforts</h4>
                        <ul>
                            {activity.segment_efforts.map(s => {
                                return (
                                    <li key={`segment_effort_${s.id}`}>{s.name}: {s.duration_in_seconds} seconds. <a className="text-text-accent" href={s.url}>View segment</a></li>
                                )
                            })}
                        </ul>
                    </div>
                } */}
      </>
    );
  }
}
export default ActivityShow;

import React from 'react';

const Cont = ({ children, classes }) => (
  <div
    className={`bg-background-quaternary border border-border-darker-yellow rounded-lg p-12pt md:p-24pt ${classes}`}
  >
    {children}
  </div>
);

export default Cont;

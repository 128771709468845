import distance from '@turf/distance';
import { point } from '@turf/helpers';

const coordinatesDistance = (coordinates) => {
  let d = 0;
  for (let i = 0; i < coordinates.length - 2; i++) {
    const pointA = point([coordinates[i].lng, coordinates[i].lat]);
    const pointB = point([coordinates[i + 1].lng, coordinates[i + 1].lat]);

    // Add the distance between the two points to the total distance (is returned in kilometers, so we multiply by 1000 to get meters)
    d += distance(pointA, pointB) * 1000;
  }
  return d;
};

export default coordinatesDistance;

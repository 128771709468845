import React, { useState, useEffect } from 'react';
import apiFetch from '../../src/js/fetch';
import BarLoader from 'react-spinners/BarLoader';

function BusinessForm() {
  const [formState, setFormState] = useState({
    email: '',
    company: '',
    phone: '',
  });
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [feedback, setFeedback] = useState(null);
  useEffect(() => {
    setShow(true); // Wait for component to mount before showing form (mitigating spam bots).
  }, []);

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [formState]);

  const handleChange = (e) => {
    const value = e.target.value;
    setFormState({
      ...formState,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formState.email || !formState.phone || !formState.company) {
      setError('Please fill out all fields.');
      return;
    }

    setError(null);
    setFeedback(null);
    setProcessing(true);

    // send message to backend
    try {
      const sent = await apiFetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contact: {
            email: formState.email,
            message:
              'Company: ' + formState.company + ', phone: ' + formState.phone,
          },
        }),
      });

      console.log('sent', sent);
      setFeedback('Thanks, your message has been sent!');

      setFormState({
        email: '',
        company: '',
        phone: '',
      });
    } catch (error) {
      console.log('Error sending message:', error);
      const err = await error.json();

      setError(err.error_message);
    }

    setProcessing(false);
  };

  if (!show) return null;

  return (
    <form method='POST' onSubmit={onSubmit}>
      <div className='w-full md:max-w-[35rem] mx-auto rounded-lg mb-6'>
        <div className='w-full'>
          <label htmlFor='company' className='input-label'>
            Bedrift
          </label>
          <input
            type='text'
            name='company'
            id='company'
            value={formState.company}
            required={true}
            onChange={handleChange}
            className='w-full px-8pt h-fields rounded-button border-border border bg-background-quaternary text-text-body'
            placeholder='Bedrift *'
          />
        </div>
        <div className='w-full mt-2'>
          <label htmlFor='email' className='input-label'>
            E-post
          </label>
          <input
            type='email'
            name='email'
            id='email'
            value={formState.email}
            required={true}
            onChange={handleChange}
            className='w-full px-8pt h-fields rounded-button border-border border bg-background-quaternary text-text-body'
            placeholder='E-post *'
          />
        </div>
        <div className='w-full mt-2'>
          <label htmlFor='phone' className='input-label'>
            Telefon
          </label>
          <input
            type='tel'
            name='phone'
            id='phone'
            className='w-full px-8pt h-fields rounded-button border-border border bg-background-quaternary text-text-body'
            value={formState.phone}
            onChange={handleChange}
            placeholder='Telefon *'
          />
        </div>
        <div className='flex w-full mt-2 items-center'>
          <input
            type='checkbox'
            id='privacy'
            required={true}
            value='accepted privacy policy'
          />
          <label htmlFor='privacy' className='text-text-body-inverted ml-2'>
            Ja, jeg godtar{' '}
            <a href='/privacy-policy' className='underline'>
              vilkår for personvern
            </a>
          </label>
        </div>
        {error && <div className='text-text-grey-600 mt-4'>{error}</div>}
        {processing && (
          <div className='mt-4'>
            <BarLoader />
          </div>
        )}
        {feedback && <p className='mt-4 text-text-grey-600'>{feedback}</p>}
      </div>
      <div className='w-full flex md:justify-center'>
        <button className='button flex-1 mx-auto' disabled={processing}>
          Kontakt meg
        </button>
      </div>
    </form>
  );
}

export default BusinessForm;

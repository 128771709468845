import React from 'react';
import SuperPopup from '../../SuperPopup';
import icWarning from '../../../images/icons/ic-warning.svg';
import apiFetch from '../../../src/js/fetch';

class CancelSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      processing: false,
      error: null,
    };
  }

  componentDidMount() {
    const cancelSubscriptionTrigger = document.querySelector(
      '#cancel-subscription'
    );
    this.openTriggerClickListener = cancelSubscriptionTrigger.addEventListener(
      'click',
      this.openModal
    );
  }

  componentWillUnmount() {
    if (this.openTriggerClickListener) {
      this.openTriggerClickListener.removeEventListener(
        'click',
        this.openModal
      );
    }
  }

  openModal = (e) => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    if (this.state.processing) return;
    this.setState({ open: false });
  };

  cancelSubscription = async () => {
    this.setState({ processing: true });

    try {
      const deleted = await apiFetch('/api/paddle/cancel-subscription', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      window.location.href = '/profile/settings';
    } catch (error) {
      console.log('Error canceling subscription:', error);
      this.setState({ error: 'Something went wrong. Please try again later.' });
    }
    this.setState({ processing: false });
  };

  render() {
    const { open, processing, error } = this.state;
    return (
      <SuperPopup
        modalTitle='Cancel subscription'
        open={open}
        onClose={this.closeModal}
        icon={icWarning}
        title='Are you sure you want to cancel your Full access subscription?'
        text='If you cancel your subscription, your account will be downgraded to a Free account when the current billing period ends. You can upgrade your account at any time.'
        buttons={[
          { text: 'Cancel', onClick: this.closeModal },
          {
            text: 'Cancel subscription',
            onClick: this.cancelSubscription,
            style: 'dark',
          },
        ]}
        processing={processing}
        error={error}
      />
    );
  }
}

export default CancelSubscription;

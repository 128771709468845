import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import icClose from '../images/icons/ic-exit-white.svg';

class CookieBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const cookie = Cookies.get(`closed_cookie_banner`);

    // The script is in "revoked" consent by default
    if (cookie) {
      if (cookie === 'accept') {
        // Grant consent
        if (window.fbq) window.fbq('consent', 'grant');
      }
    } else {
      this.setState({ open: true });
    }

    const openTrigger = document.querySelector('.update-cookie-settings');
    if (openTrigger) {
      this.triggerListener = openTrigger.addEventListener('click', this.open);
    }
  }

  componentWillUnmount() {
    if (this.triggerListener) {
      const openTrigger = document.querySelector('.update-cookie-settings');
      openTrigger.removeEventListener('click', this.triggerListener);
    }
  }

  open = () => {
    this.setState({ open: true });
  };

  deny = () => {
    let expire = new Date();
    expire.setFullYear(expire.getFullYear() + 1);
    Cookies.set(`closed_cookie_banner`, 'deny', { expires: expire });

    // Revoke consent
    if (window.fbq) window.fbq('consent', 'revoke');

    // Close
    this.setState({ open: false });
  };

  close = () => {
    // Close
    this.setState({ open: false });
  };

  accept = () => {
    let expire = new Date();
    expire.setFullYear(expire.getFullYear() + 1);
    Cookies.set(`closed_cookie_banner`, 'accept', { expires: expire });

    // Grant consent
    if (window.fbq) window.fbq('consent', 'grant');

    // Close
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    if (!open) {
      return null;
    }
    return (
      <div className='fixed bottom-0 left-0 right-0 z-[1000] shadow-lg bg-secondary text-text-body-inverted p-8 w-full'>
        <div className='max-w-text mx-auto md:flex items-center'>
          <p className='pr-12 mb-6 md:mb-0'>
            We use cookies to improve your experience on our site, for ads and
            analytics. Read more in our{' '}
            <a
              href='/cookie-policy'
              target='_blank'
              className='underline hover:no-underline'
            >
              Cookie Policy
            </a>
          </p>
          {/* <button
            onClick={this.close}
            title='Close popup'
            className='absolute top-0 right-0 p-3'
          >
            <img src={icClose} className='w-3' />
          </button> */}
          <div className='flex'>
            <button onClick={this.deny} className='mr-4 button bordered-light'>
              REJECT
            </button>
            <button onClick={this.accept} className='button light'>
              ACCEPT
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CookieBanner;

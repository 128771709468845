import React from 'react';
import ContestSegmentsMap from '../../src/js/contests/segments';
import Segment from './Segments/Item';

class ExpandedContest extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.mapHandler = new ContestSegmentsMap({
      mapSelector: 'expanded-contest-map',
      seeCurrentLocation: true,
    });
    this.mapHandler.addSegmentsToMap(this.props.segments);
  }

  componentWillUnmount() {
    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  render() {
    const { segments } = this.props;

    return (
      <div className={`w-full h-full flex flex-col-reverse lg:flex-row`}>
        <div className='flex-1 min-h-0 lg:overflow-y-auto'>
          {segments.map((segment, index) => {
            return <Segment key={segment.id} segment={segment} index={index} />;
          })}
        </div>

        <div
          className='h-[70vh] lg:h-auto lg:flex-1 mb-4 lg:mb-0 lg:ml-4 rounded map'
          id='expanded-contest-map'
        ></div>
      </div>
    );
  }
}
export default ExpandedContest;

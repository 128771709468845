import React from 'react';

/**
 * This component is used in the UpdatePaymentMethod page. The UpdatePaymentMethod page is the a simple page, with a link that includes a "_ptxn" (paddle transaction ID) parameter. When Paddle js script is loaded,
 * it will check for this parameter and if it exists, it will open the checkout modal AUTOMATICALLY.
 */
class UpdatePaymentMethod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  eventCallback = (e) => {
    const event = e.detail;
    if (event.name === 'checkout.closed') {
      console.log('checkout.closed');

      // Navigate to the home page
      window.location.replace('/profile/settings');
    }
  };

  async componentDidMount() {
    console.log('componentDidMount UpdatePaymentMethod');

    document.addEventListener('paddleEventCallback', this.eventCallback);
  }

  componentWillUnmount() {
    // Close the checkout when the component unmounts
    if (window.Paddle) {
      window.Paddle.Checkout.close();
    }

    // Remove the paddleEventCallback listener
    document.removeEventListener('paddleEventCallback', this.eventCallback);
  }

  render() {
    return (
      <div className='w-full sub-checkout-container bg-background-quaternary'></div>
    );
  }
}

export default UpdatePaymentMethod;

import MapHandler from '../mapHandler';
import apiFetch from '../fetch';
import segmentStartIcon from '../../../images/icons/ic-segment-start.svg';
import segmentEndIcon from '../../../images/icons/ic-segment-end.svg';
export default class BrowseSegments extends MapHandler {
  constructor(options = {}) {
    super(options);

    this.segmentLayers = [];

    this.onBoundsChange = options.onBoundsChange;

    // Bindings
    this.setupBoundsListener = this.setupBoundsListener.bind(this);
    this.boundsListenerCallback = this.boundsListenerCallback.bind(this);
    this.highlightSegment = this.highlightSegment.bind(this);
    this.unHighlightSegment = this.unHighlightSegment.bind(this);

    this.setupBoundsListener();
  }

  /**
   * =========================================================
   * Bounds
   */
  setupBoundsListener() {
    this.boundsListenerCallback(); // Call once to set initial bounds

    // Then listen for changes
    this.map.on('moveend', this.boundsListenerCallback);
  }

  boundsListenerCallback(e) {
    const bounds = this.map.getBounds();
    const currentZoom = this.map.getZoom();

    this.onBoundsChange(bounds, currentZoom);
  }

  /**
   * =========================================================
   * Segments
   */
  addSegmentsToMap(segments) {
    // Remove previous segments
    // this.removeSegmentLayers();

    console.log('Adding segments to map', segments);

    const remove = [];
    const onlyShowDots = this.map.getZoom() < 12;
    this.segmentLayers.forEach((layer, index) => {
      // console.log(layer.segmentId);
      const exists = segments.find(
        (s) => s.id === layer.segmentId && layer.dotView == onlyShowDots
      );
      if (!exists) {
        // console.log('Removing layer', layer);
        if (layer.layer) this.map.removeLayer(layer.layer);
        this.map.removeLayer(layer.tooltip);
        this.map.removeLayer(layer.startIconLayer);
        if (layer.stopIconLayer) this.map.removeLayer(layer.stopIconLayer);
        remove.push(layer.segmentId);
      }
    });
    remove.forEach((sId) =>
      this.segmentLayers.splice(
        this.segmentLayers.findIndex((s) => s.segmentId == sId),
        1
      )
    );

    // console.log({ segmentLayers: this.segmentLayers });

    // Add new segments
    segments.forEach(async (segment, index) => {
      const existingLayer = this.segmentLayers.findIndex(
        (layer) => layer.segmentId === segment.id
      );
      if (existingLayer > -1) {
        // console.log({ existingLayer: this.segmentLayers[existingLayer] });
        // Segment already exists

        // Update tooltip text
        // console.log(this.segmentLayers[existingLayer].tooltip);
        this.segmentLayers[existingLayer].tooltip.setContent(`S${index + 1}`);

        // Don't add it again
        return;
      }

      // console.log({ segment });

      let coords = null;
      if (segment.sample_file) {
        // Fetch the coordinates.
        const d = await apiFetch(segment.sample_file);
        if (d && d.coordinates) {
          coords = d.coordinates.map((c) => L.latLng(c.lat, c.lng, c.alt));
        }
      }

      if (coords) {
        // Add the segment to the map
        const startIcon = L.icon({
          iconUrl: segmentStartIcon,
          iconSize: [20, 24],
          iconAnchor: [10, 24],
        });
        const layers = onlyShowDots
          ? L.marker(coords[0], { icon: startIcon }).addTo(this.map)
          : this.drawPolyline(coords, false);

        const tooltip = L.tooltip({ permanent: true })
          .setLatLng([segment.start_lat, segment.start_lng])
          .setContent(`S${index + 1}`)
          .addTo(this.map);

        this.segmentLayers.push({
          layer: onlyShowDots ? null : layers.polyline,
          tooltip: tooltip,
          segmentId: segment.id,
          latLng: [segment.start_lat, segment.start_lng],
          stopIconLayer: onlyShowDots ? null : layers.stopIcon,
          startIconLayer: onlyShowDots ? layers : layers.startIcon,
          dotView: onlyShowDots,
        });
      }
    });
  }

  removeSegmentLayers() {
    this.segmentLayers.forEach((layer) => {
      // console.log('Removing layer', layer);
      if (layer.layer) this.map.removeLayer(layer.layer);
      // this.map.removeLayer(layer.layer);
    });
    this.segmentLayers = [];
  }

  highlightSegment(segmentId) {
    // console.log('Highlighting segment', segmentId);
    const layer = this.segmentLayers.find(
      (layer) => layer.segmentId === parseInt(segmentId)
    );
    if (layer && layer.layer) {
      // this.map.fitBounds(layer.layer.getBounds());
      layer.layer.setStyle({ weight: 5 });
    }
    if (layer && layer.startIconLayer) {
      layer.startIconLayer.setIcon(
        L.icon({
          iconUrl: segmentStartIcon,
          iconSize: [30, 36],
          iconAnchor: [15, 36],
        })
      );
    }
  }
  unHighlightSegment(segmentId) {
    console.log('Unhighlighting segment', segmentId);
    const layer = this.segmentLayers.find(
      (layer) => layer.segmentId === parseInt(segmentId)
    );
    if (layer && layer.layer) {
      layer.layer.setStyle({ weight: 3 });
    }
    if (layer && layer.startIconLayer) {
      layer.startIconLayer.setIcon(
        L.icon({
          iconUrl: segmentStartIcon,
          iconSize: [20, 24],
          iconAnchor: [10, 24],
        })
      );
    }
  }
}

import React from 'react';
import { formatDuration } from '../../../src/js/lib/duration';
import { formatNumber } from '../../../src/js/lib/number';
import BarLoader from 'react-spinners/BarLoader';
import Pagination from '../../Pagination';
import apiFetch from '../../../src/js/fetch';
import Container from '../../ContentContainer';
import IcFilter from '../../svg/IcFilter';
import IcDefaultAvatar from '../../../images/icons/ic-person.svg';
import ProfilePicture from '../../Athletes/ProfilePicture';

class DistanceLeaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFetched: false,
      contestId: props.contest_id,
      challengeType: props.distance_challenge_type,
      userId: props.user_id,
      contestants: [],
      activitiesGroupedMonthly: [],
      loading: true,
      contestMonths: props.contest_months,
      contestantCountries: props.contestant_countries,

      numPages: 1,
      currentPage: 1,
      perPage: null,

      orderBy: 'total_distance_in_meters',
      filterGender: 'all',
      filterMonth: 'all',
      filterCountry: 'all',
      openFilter: false,
    };

    this.countryFilter = document.querySelector('[name="filterCountry"]');
    this.monthFilter = document.querySelector('[name="filterMonth"]');
    this.genderFilter = document.querySelector('[name="filterGender"]');

    this.currentPositionButton = document.querySelector(
      '[data-current_position]'
    );
  }

  componentDidMount() {
    this.fetchContestants(this.state.currentPage);

    // Add the reloadLeaderboard function to the window object so it can be called from the outside
    window.reloadLeaderboard = this.reloadLeaderboard;

    // Listen for clicks outside the filter dropdown
    document.body.addEventListener('click', this.handleClickOutsideFilter);

    // Listen for filter changes
    this.countryFilter.addEventListener('change', this.onChange);
    this.monthFilter.addEventListener('change', this.onChange);
    this.genderFilter.addEventListener('change', this.onChange);

    // Listen for clicks on the current position button that will take the user to the page where the user is
    this.currentPositionButton.addEventListener('click', this.goToPositionPage);
  }

  reloadLeaderboard = () => {
    this.setState({ currentPage: 1, contestants: [], loading: true }, () => {
      this.fetchContestants(this.state.currentPage);
    });
  };

  componentWillUnmount() {
    window.reloadLeaderboard = null;
    document.body.removeEventListener('click', this.handleClickOutsideFilter);

    this.countryFilter.removeEventListener('change', this.onChange);
    this.monthFilter.removeEventListener('change', this.onChange);
    this.genderFilter.removeEventListener('change', this.onChange);

    this.currentPositionButton.removeEventListener(
      'click',
      this.goToPositionPage
    );
  }

  goToPositionPage = (e) => {
    const position = this.currentPositionButton.getAttribute(
      'data-current_position'
    );

    window.scrollTo({
      top:
        document.querySelector('.leaderboard').getBoundingClientRect().top +
        window.scrollY -
        100,
      behavior: 'smooth',
    });

    if (position && this.state.perPage) {
      // Calculate the page based on the position
      const page = Math.ceil(parseInt(position) / this.state.perPage);

      console.log({ page, perPage: this.state.perPage, position });
      this.onPaginate(page);
    }
  };

  onPaginate = (page) => {
    if (page !== this.state.currentPage) {
      this.setState({ currentPage: page }, () => {
        this.fetchContestants(page);
      });
    }
  };

  fetchContestants = async (page) => {
    this.setState({ loading: true });

    const {
      contestId,
      filterCountry,
      filterMonth,
      filterGender,
      initialFetched,
    } = this.state;
    try {
      const data = await apiFetch(`/api/challenges/leaderboard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contest_id: contestId,
          page: page,
          filter: {
            gender: filterGender,
            country: filterCountry,
          },
          order: {
            month: filterMonth,
            // field: orderBy,
          },
        }),
      });

      // console.log({ data });

      this.setState({
        initialFetched: true,
        contestants: data.contestants,
        activitiesGroupedMonthly: data.monthly_grouped_activities,
        numPages: data.total_pages,
        perPage: data.per_page,
      });
      if (initialFetched) {
        this.updatePodium(data.contestants, data.filtered);
      }
    } catch (e) {
      console.log({ e });
      const err = e.json ? await e.json() : null;
      console.log({ err });

      this.setState({
        loading: false,
        error:
          (err && err.error_message) ||
          'Something went wrong. Please try again later.',
      });
    }
    this.setState({ loading: false });
  };

  updatePodium = (contestants, filtered) => {
    // When the leaderboard is updated, we need to update the podium which is a static html element on the page
    const { userId } = this.state;

    const podiumContestants = contestants
      .slice(0, 3)
      .filter((c) =>
        c.filtered
          ? c.period_total_distance_in_meters > 0 ||
            c.new_total_distance_in_meters > 0
          : c.position
      );
    const podium = document.querySelector('.podium');

    const podiumPositionLabel = document.querySelector(
      '.podium__header-position-label'
    );

    if (filtered) {
      podiumPositionLabel.classList.add('hidden');
    } else {
      podiumPositionLabel.classList.remove('hidden');
    }

    if (podium) {
      podiumContestants.forEach((contestant, index) => {
        console.log({ contestant });
        // When filtering, the position on a contestant is null and is calculated by the order. That's why we use index + 1 here when querySelector
        const podiumPosition = podium.querySelector(`.podium__${index + 1}`);
        console.log({ podiumPosition });
        if (podiumPosition) {
          const name = podiumPosition.querySelector('.podium__athletename');
          const pointsLabel = podiumPosition.querySelector('.podium__points');
          const avatar = podiumPosition.querySelector('.podium__avatar');
          const avatarImg = avatar.querySelector('img');

          name.innerHTML = contestant.user.name;
          name.classList.add('font-medium');

          pointsLabel.innerHTML = `${formatNumber(
            (
              (contestant.filtered
                ? contestant.period_total_distance_in_meters ||
                  contestant.new_total_distance_in_meters
                : contestant.total_distance_in_meters) / 1000
            ).toFixed(0)
          )} km`;

          if (contestant.user.avatar) {
            // The contestant has an avatar
            if (
              avatarImg &&
              avatarImg.classList.contains('avatar__image-default')
            ) {
              // If the current avatar is an image, and it's the default avatar, we change it.
              avatarImg.src = contestant.user.avatar;

              avatarImg.classList.remove('avatar__image-default');
              avatarImg.classList.add('avatar__image');
            } else if (avatarImg) {
              // The avatar is an image, we update the src only
              avatarImg.src = contestant.user.avatar;
            } else {
              // The avatar is not an image, but a Span element. We replace it with an image
              avatar.innerHTML = ''; // Remove the text

              const img = document.createElement('img');
              img.src = contestant.user.avatar;
              img.classList.add('avatar__image');
              avatar.appendChild(img);
            }
          } else {
            // The contestant has no avatar
            let textElem = null;
            if (avatarImg) {
              // If the current avatar is an image, we change it to a Span element
              avatar.innerHTML = ''; // Remove the image

              textElem = document.createElement('span');
              textElem.innerHTML = contestant.user.avatar_text;
              textElem.classList.add('avatar__text');
              avatar.appendChild(textElem);
            } else {
              // The avatar is not an image, but a Span element. We update the text
              textElem = avatar.querySelector('span');
              textElem.innerHTML = contestant.user.avatar_text;
            }

            // Set textElem. css bg-color/text-color
            textElem.classList.remove(
              'avatar__text_theme_pink',
              'avatar__text_theme_green',
              'avatar__text_theme_blue'
            );
            textElem.classList.add(contestant.user.avatar_theme);
          }
        }
      });

      const unfilledPodiumItems = 3 - podiumContestants.length;
      for (let i = 0; i < unfilledPodiumItems; i++) {
        const podiumPosition = podium.querySelector(`.podium__${3 - i}`);
        if (podiumPosition) {
          const name = podiumPosition.querySelector('.podium__athletename');
          const pointsLabel = podiumPosition.querySelector('.podium__points');
          const avatar = podiumPosition.querySelector('.podium__avatar');
          const avatarImg = avatar.querySelector('img');

          name.innerHTML = 'Up for grabs';
          name.classList.remove('font-medium');

          pointsLabel.innerHTML = '';

          if (avatarImg) {
            // If the avatar is an image, we update the src only and set the default avatar and style
            avatarImg.src = IcDefaultAvatar;
            avatarImg.classList.remove('avatar__image');
            avatarImg.classList.add('avatar__image-default');
          } else {
            // The avatar is not an image, but a Span element. We replace it with an image using the default avatar and style
            avatar.innerHTML = ''; // Remove the text

            const img = document.createElement('img');
            img.src = IcDefaultAvatar;
            img.classList.add('avatar__image-default');
            avatar.appendChild(img);
          }
        }
      }
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchContestants(this.state.currentPage);
    });
  };

  toggleFilter = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handleClickOutsideFilter = (e) => {
    if (
      this.state.openFilter &&
      !e.target.closest('.challenge-leaderboard-filter') &&
      !e.target.closest('.filter-trigger')
    ) {
      this.setState({ openFilter: false });
    }
  };

  render() {
    const {
      contestants,
      activitiesGroupedMonthly,
      numPages,
      currentPage,
      userId,
      contestMonths,
      contestantCountries,
      challengeType,
      orderBy,
      filterCountry,
      filterGender,
      filterMonth,
      openFilter,
    } = this.state;

    return (
      <Container classes='leaderboard'>
        <h2 className='mini-label font-medium'>Leaderboard</h2>
        <div className='w-full overflow-x-auto challenge-leaderboard'>
          <table className='w-full border-spacing-y-2 border-separate'>
            <thead className=''>
              <tr className=''>
                <th className=''>#</th>
                <th className=''>Contestant</th>
                {contestMonths.map((month, i) => {
                  const mnth = `${month[1]}-${('0' + month[0]).slice(-2)}-01`;
                  return (
                    <th
                      key={`${month[0]}-${month[1]}`}
                      className={
                        filterMonth === mnth
                          ? 'leaderboard__row-item-highlight'
                          : ''
                      }
                    >
                      {month[2]}
                    </th>
                  );
                })}
                <th className=''>Total km</th>
              </tr>
            </thead>
            <tbody>
              {contestants.map((contestant, i) => (
                <tr
                  key={contestant.id}
                  className={`${
                    contestant.user_id === userId ? 'current-user' : ''
                  }`}
                >
                  <td className=''>
                    {contestant.filtered
                      ? contestant.period_total_distance_in_meters > 0 || // if filtered by period, we have the period_total_distance_in_meters field.
                        contestant.new_total_distance_in_meters > 0 // if filtered not by period, we have the new_total_distance_in_meters field.
                        ? `${i + 1}.`
                        : '-'
                      : contestant?.position && contestant.position > 0
                      ? `${contestant.position}.`
                      : '-'}
                  </td>
                  <td className='flex items-center'>
                    <ProfilePicture {...contestant.user} />
                    <a className='underline' href={contestant.user.url}>
                      {contestant.user.name}
                    </a>
                  </td>
                  {contestMonths.map((month, i) => {
                    const mnth = `${month[1]}-${('0' + month[0]).slice(-2)}-01`;
                    const activityMonth = activitiesGroupedMonthly.find(
                      (m) => m.month === mnth
                    );
                    const distance = activityMonth?.users?.find(
                      (u) => u.id === contestant.user_id
                    )?.total_distance_in_meters;

                    return (
                      <td
                        key={`${month[2]}-${contestant.id}`}
                        className={
                          filterMonth === mnth
                            ? 'leaderboard__row-item-highlight'
                            : ''
                        }
                      >
                        {distance
                          ? `${formatNumber((distance / 1000).toFixed(0))}km`
                          : '-'}
                      </td>
                    );
                  })}
                  <td className=''>
                    {contestant.total_distance_in_meters
                      ? formatNumber(
                          (contestant.total_distance_in_meters / 1000).toFixed(
                            0
                          )
                        )
                      : '0'}{' '}
                    km
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {this.state.loading ? <BarLoader /> : null}

          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            onPaginate={this.onPaginate}
          />
        </div>
      </Container>
    );
  }
}
export default DistanceLeaderboard;

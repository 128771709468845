import React from 'react';
import SuperPopup from '../SuperPopup';
import icWarning from '../../images/icons/ic-warning.svg';
import apiFetch from '../../src/js/fetch';

class Withdraw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      processing: false,
      error: null,

      contestId: props.contest_id,
    };
  }

  componentDidMount() {
    const deleteAccountTrigger = document.querySelector('#withdraw');
    this.openTriggerClickListener = deleteAccountTrigger.addEventListener(
      'click',
      this.openModal
    );
  }

  componentWillUnmount() {
    if (this.openTriggerClickListener) {
      this.openTriggerClickListener.removeEventListener(
        'click',
        this.openModal
      );
    }
  }

  openModal = (e) => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    if (this.state.processing) return;
    this.setState({ open: false });
  };

  withdraw = async (e) => {
    const { contestId, processing } = this.state;

    if (processing) return;

    this.setState({ processing: true });

    try {
      await apiFetch('/api/withdraw-from-contest', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contestId: contestId }),
      });

      location.reload(); // Reload the page to update the leaderboard
    } catch (error) {
      console.log('Error withdrawing:', error);
      this.setState({ error: 'Something went wrong. Please try again later.' });
    }
    this.setState({ processing: false });
  };

  render() {
    const { open, processing, error } = this.state;
    return (
      <SuperPopup
        modalTitle='Withdraw'
        open={open}
        onClose={this.closeModal}
        icon={icWarning}
        title='Are you sure?'
        text='You will lose your spot on the leaderboard.'
        buttons={[
          { text: 'Cancel', onClick: this.closeModal },
          {
            text: 'Withdraw',
            onClick: this.withdraw,
            style: 'dark',
          },
        ]}
        processing={processing}
        error={error}
      />
    );
  }
}

export default Withdraw;

import React from 'react';
import apiFetch from '../../src/js/fetch';
import ContentContainer from '../ContentContainer';
import icBack from '../../images/icons/ic-backarrow.svg';
import editIcon from '../../images/icons/ic-edit.svg';
import closeIcon from '../../images/icons/ic-exit.svg';
import SegmentType from '../SegmentType';
import coordinates from '../../images/icons/ic-coordinates.svg';
import elevation from '../../images/icons/ic-elevation.svg';
import BarLoader from 'react-spinners/BarLoader';
import BrowseSegments from '../Segments/Browse';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import focusLock from 'dom-focus-lock';
import moment from 'moment';
import { addTippys } from '../../src/js/tooltips';
import infoIcon from '../../images/icons/ic-info.svg';

class CreateContest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      name: props.name || '',
      description: props.description || '',
      from: props.from || '',
      to: props.to || '',
      access: props.access || 'public',
      challengeType: props.challenge_type || 'segment',
      distanceChallengeTypes: props.distance_challenge_types || [],
      distanceChallengeType: props.distance_challenge_types.length
        ? props.distance_challenge_types[0].id
        : '',

      loading: false,
      segments: props.segments || [],
      destroySegments: [],
      addNewSegments: [],

      showSegmentFinder: false,
    };

    this.minFromDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    this.minToDate = moment().format('YYYY-MM-DD');

    this.segmentFinderRef = React.createRef();
    this.focusLockRef = React.createRef();
  }

  componentDidMount() {
    addTippys();
  }

  componentWillUnmount() {
    console.log('unmounting');

    if (this.focusLock) {
      focusLock.off(this.focusLockRef.current);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, from, to, access, description, segments, challengeType } =
      this.state;
    const { edit, contest_id } = this.props;

    if (
      !name ||
      !description ||
      !from ||
      !to ||
      !access ||
      !challengeType ||
      (challengeType == 'segment' && !segments.length)
    ) {
      console.log('Missing data:', {
        name,
        description,
        from,
        to,
        access,
        segments,
      });
      this.setState({
        loading: false,
        error:
          'You need to fill in all fields, and select at least one segment.',
      });
      return;
    }

    this.setState({ loading: true, error: null });

    // console.log('Submit form:', this.state.destroySegments);
    const body = {
      // :name, :from, :to, :public, :description, contest_segments_attributes: [ :segment_id ]
      contest: {
        name: name,
        to: to,
        public: challengeType == 'segment' ? access : 'private', // If not segment challenge, always private
        description: description,
        challenge_type: challengeType,
      },
    };

    if (challengeType == 'distance') {
      // If the challenge type is distance, we need to add the distance challenge type to the contest.
      body.contest.distance_challenge_type_id = parseInt(
        this.state.distanceChallengeType
      );
    } else if (challengeType == 'segment') {
      // If the challenge type is segment, we need to add the segments to the contest_segments_attributes array.
      body.contest.contest_segments_attributes = [
        ...segments
          .filter((s) => !s.contest_id)
          .map((s) => ({ segment_id: s.id })),
        ...this.state.destroySegments.map((s) => ({ id: s, _destroy: true })),
      ];
    }

    if (!edit) {
      // Don't allow changing the from date on an existing contest
      body.contest.from = from;
    }

    console.log({ body });

    try {
      const data = await apiFetch(
        `/api/challenges${edit ? `/${contest_id}` : ''}`,
        {
          method: edit ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(body),
        }
      );

      window.location.href = data.redirect_to;
    } catch (e) {
      console.log({ e });
      const err = await e.json();
      console.log({ err });
      this.setState({
        loading: false,
        error:
          err.error_message || 'Something went wrong. Please try again later.',
      });
    }
  };

  handleChange = (event) => {
    if (event.target.name === 'from') {
      // If the from date changes, reset the to date.
      this.minToDate = moment(event.target.value)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      if (
        moment(event.target.value).isAfter(this.state.to) ||
        moment(event.target.value).isSame(this.state.to)
      ) {
        this.setState({ to: '' });
      }
    }
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    });
  };

  onAddSegmentToAddList = (segment) => {
    const exists = this.state.addNewSegments.find((s) => s.id === segment.id);
    if (exists) {
      this.setState({
        addNewSegments: this.state.addNewSegments.filter(
          (s) => s.id !== segment.id
        ),
      });
    } else {
      this.setState({
        addNewSegments: [...this.state.addNewSegments, segment],
      });
    }
  };

  onAddSegmentsToList = () => {
    const updated = [...this.state.segments];
    for (const segment of this.state.addNewSegments) {
      const exists = updated.find((s) => s.id === segment.id);
      if (!exists) {
        // Only add the segment if it's not already in the list
        updated.push(segment);
      }
    }

    this.setState({
      error: null,
      segments: updated,
      addNewSegments: [],
      showSegmentFinder: false,
    });

    // Enable scrolling on the body
    clearAllBodyScrollLocks();
  };

  onAddSegment = (segment) => {
    const { edit } = this.props;
    console.log({ segment, edit });

    /**
     * When edit=true the existing segments are objects with Segment data, and a contest_segment_id.
     * When edit=false segments are just Segment data.
     */
    const exists = this.state.segments.find((s) => s.id === segment.id);
    if (exists) {
      if (edit && exists.contest_id) {
        const ok = confirm(
          'This segment is already part of the live challenge. Do you really want to remove it? This will remove all data for this segment from the challenge.'
        );
        if (!ok) {
          return;
        }
        // This segment is already part of the contest, so we need to remove it, and add it to the destroySegments array, so it gets deleted when we update the contest.
        this.setState({
          destroySegments: [
            ...this.state.destroySegments,
            exists.contest_segment_id,
          ],
        });
      }

      console.log('Already added');
      this.setState({
        segments: this.state.segments.filter((s) => s.id !== segment.id),
      });
    } else {
      this.setState({
        segments: [...this.state.segments, segment],
      });
    }
  };

  toggleSegmentFinder = async () => {
    const { showSegmentFinder } = this.state;

    const open = !showSegmentFinder;

    if (!open) {
      // Enable scrolling on the body
      clearAllBodyScrollLocks();

      // Reset the added segments
      this.setState({ addNewSegments: [] });
    }

    await this.setState({ showSegmentFinder: open });

    if (open) {
      // Disable scrolling on the body, and lock focus inside the modal
      disableBodyScroll(this.segmentFinderRef.current);
      this.focusLock = focusLock.on(this.focusLockRef.current);
    }
  };

  render() {
    const {
      loading,
      name,
      from,
      to,
      access,
      description,
      segments,
      addNewSegments,
      error,
      showSegmentFinder,
      challengeType,
      distanceChallengeTypes,
      distanceChallengeType,
    } = this.state;
    const { edit } = this.props;

    return (
      <section className='lg:pt-2 lg:pb-20'>
        <div className='flex mb-4'>
          <img src={icBack} className='btn_img_classes mr-1 w-4' />
          <a
            href={'/challenges'}
            className='button-text mr-6 text-text-grey-600'
          >
            Cancel
          </a>
        </div>
        <ContentContainer classes='flex flex-col items-center mb-8 lg:mb-0'>
          <div className='flex flex-col xl:w-2/5'>
            <h1 className='small-heading font-medium'>Create Challenge</h1>
            <form
              id='create-contest-form'
              className=''
              method='post'
              onSubmit={this.handleSubmit}
            >
              {/* INPUT TITLE */}
              <div className='mb-7'>
                <h2 className='mini-label font-medium mb-2'>
                  Name of challenge
                </h2>
                <input
                  type='text'
                  name='name'
                  id='name'
                  autoFocus
                  className={`input w-full appearance-none`}
                  required={true}
                  onChange={this.handleChange}
                  value={name}
                  placeholder='Challenge title'
                />
              </div>
              {/* INPUT TITLE END */}

              {/* CHOOSE DATE */}
              <div className='mb-7'>
                <h2 className='mini-label font-medium mb-1'>Challenge date</h2>
                <div className='grid md:grid-cols-2 gap-2 lg:gap-4'>
                  <div>
                    <label
                      htmlFor='from'
                      className='mini-label font-thin text-text-grey-500 xl:pb-2'
                    >
                      From
                    </label>
                    <input
                      type='date'
                      disabled={!!edit}
                      name='from'
                      id='from'
                      min={this.minFromDate}
                      className={`input w-full appearance-none lg:mt-1 ${
                        edit ? 'opacity-40' : ''
                      }`}
                      onChange={this.handleChange}
                      required={true}
                      value={from}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='to'
                      className='mini-label font-thin text-text-grey-500'
                    >
                      To
                    </label>
                    <input
                      type='date'
                      name='to'
                      id='to'
                      className={`input w-full appearance-none lg:mt-1 ${
                        from ? '' : 'opacity-50'
                      }`}
                      onChange={this.handleChange}
                      required={true}
                      min={this.minToDate}
                      value={to}
                      disabled={!from}
                    />
                  </div>
                </div>
                <p className='mini-text text-text-grey-600 mt-2'>
                  If the start date has passed, your activities since then will
                  be added to the leaderboards..
                </p>
              </div>
              {/* CHOOSE DATE END */}

              {/* SELECT CHALLENGE TYPE */}
              <div className='mb-7'>
                <h2 className='mini-label font-medium mb-2'>Select type</h2>

                {edit && (
                  <p className='mini-text text-text-grey-600'>
                    You can't change type in an existing challenge.
                  </p>
                )}
                <div className='flex flex-wrap'>
                  <div
                    data-tippy-content='Compete in designated A to B segments and earn points based on your performance relative to other athletes. Ideal for those who can access specific locations.'
                    className={`flex items-start small-body-text mb-2 mr-6 ${
                      edit ? 'opacity-40' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      name='challengeType'
                      id='segment'
                      value='segment'
                      className='h-auto mr-2 translate-y-1'
                      checked={challengeType === 'segment'}
                      onChange={this.handleChange}
                      disabled={!!edit}
                    />
                    <label
                      htmlFor='segment'
                      className='text-text flex-1 min-w-0 pl-2'
                    >
                      Segment challenge
                    </label>
                  </div>
                  <div
                    className={`flex items-start small-body-text ${
                      edit ? 'opacity-40' : ''
                    }`}
                    data-tippy-content='Focus solely on accumulating distance, wherever you are. Your total distance determines your rank on the leaderboard. Perfect for participants in any location.'
                  >
                    <input
                      type='radio'
                      name='challengeType'
                      id='distance'
                      value='distance'
                      className='h-auto mr-2 translate-y-1'
                      checked={challengeType === 'distance'}
                      onChange={this.handleChange}
                      disabled={!!edit}
                    />
                    <label
                      htmlFor='distance'
                      className='text-text flex-1 min-w-0 pl-2'
                    >
                      Distance challenge
                    </label>
                  </div>
                </div>
              </div>
              {/* SELECT CHALLENGE TYPE END */}

              {/* PUBLIC OR PRIVATE */}
              <div className='mb-7'>
                <h2 className='mini-label font-medium mb-2'>Access</h2>
                <div>
                  {challengeType == 'segment' && (
                    <div className='flex items-start small-body-text mb-2'>
                      <input
                        type='radio'
                        name='access'
                        id='public'
                        value='public'
                        className='h-auto mr-2 translate-y-1'
                        checked={access === 'public'}
                        onChange={this.handleChange}
                      />
                      <label
                        htmlFor='public'
                        className='text-text flex-1 min-w-0 pl-2'
                      >
                        Public (Everyone can join)
                      </label>
                    </div>
                  )}
                  <div className='flex items-start mb-4 small-body-text'>
                    <input
                      type='radio'
                      name='access'
                      id='private'
                      value='private'
                      className='h-auto mr-2 translate-y-1'
                      checked={
                        access === 'private' || challengeType === 'distance'
                      }
                      onChange={this.handleChange}
                    />
                    <label
                      htmlFor='private'
                      className='text-text flex-1 min-w-0 pl-2'
                    >
                      Private (People with the link can join)
                    </label>
                  </div>
                </div>
              </div>
              {/* PUBLIC OR PRIVATE END */}

              {challengeType == 'distance' && (
                <div className='mb-7'>
                  <h3 className='mini-label font-medium'>Select sport</h3>
                  <select
                    className='input w-full'
                    value={distanceChallengeType}
                    onChange={this.handleChange}
                    name='distanceChallengeType'
                    disabled={!!edit}
                  >
                    {distanceChallengeTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* CHOOSE SEGMENTS */}
              {challengeType == 'segment' && (
                <div className='mb-7'>
                  <h2 className='mini-label font-medium mb-2'>
                    Select segments ({segments.length})
                  </h2>

                  {segments.map((segment, i) => (
                    <div
                      className={`flex items-center mb-2 last:mb-0`}
                      key={`segment-selected-${segment.id}`}
                    >
                      <div className='flex-1 bg-background-quinary p-3 pl-4 rounded border border-border flex justify-between'>
                        <div className='flex items-center pr-4 mr-4 border-r border-border-secondary'>
                          <input
                            type='checkbox'
                            className=''
                            id={`segment-${segment.id}`}
                            checked={true}
                            onChange={() => this.onAddSegment(segment)}
                          />
                        </div>
                        <div className='md:flex flex-1 items-center justify-between small-body-text'>
                          <div className='flex flex-col lg:flex-row lg:flex-1 flex-wrap mb-1'>
                            <span className='font-medium mb-2 lg:mb-0'>
                              {segment.name}
                            </span>
                            {segment.created_by_you && (
                              <span className='mini-label text-text-grey-600'>
                                (Created by you)
                              </span>
                            )}
                          </div>
                          <div className="flex items-center md:ml-2 flex-1 mr-4 <%= s.city.present? ? 'mb-2 md:mb-0': '' %>">
                            <SegmentType typeIconUrl={segment.sport_icon_url} />
                            <span className='pl-2 sm:pl-4 pr-2 sm:pr-4'>|</span>
                            <span className='pr-2'>
                              <img src={coordinates} className='w-[14px]' />
                            </span>
                            <span>
                              {(segment.distance_in_meters / 1000).toFixed(2)}
                            </span>
                            <span>km</span>
                            <span className='pl-2 sm:pl-4 pr-2 sm:pr-4'>|</span>
                            <span className='pr-2'>
                              <img src={elevation} className='w-[14px]' />
                            </span>
                            <span>
                              {segment.elevation
                                ? parseFloat(segment.elevation).toFixed(0)
                                : '00'}
                            </span>
                            <span>m</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <button
                    type='button'
                    className='button-text underline'
                    onClick={this.toggleSegmentFinder}
                  >
                    Add segment(s)
                  </button>
                </div>
              )}
              {loading ? (
                <div className='my-4'>
                  <BarLoader />
                </div>
              ) : null}
              {/* CHOOSE SEGMENTS END*/}

              {/* DESCRIPTION */}
              <div className='flex flex-col mb-7'>
                <h2 className='mini-label font-medium mb-2'>
                  Challenge description
                </h2>
                <textarea
                  name='description'
                  id='description'
                  required={true}
                  className='min-h-[10rem] flex-1'
                  placeholder='An epic description.'
                  onChange={this.handleChange}
                  value={description}
                ></textarea>
              </div>
              {/* DESCRIPTION END */}

              {/* SUBMIT */}
              <div className='flex items-center flex-wrap justify-end'>
                <a
                  href={'/challenges'}
                  className='button-text underline mr-6 text-text-grey-600'
                >
                  Cancel
                </a>
                <button type='submit' className='button dark'>
                  {edit ? 'Update challenge' : 'Create challenge'}
                </button>
              </div>
              {error && (
                <p className='text-text-grey-600 max-w-[35rem]'>{error}</p>
              )}
              {/* SUBMIT END  */}
            </form>

            {/* SEGMENT FINDER */}
            {showSegmentFinder && (
              <div
                role='alertdialog'
                className='fixed top-0 left-0 2xl:left-[15%] bottom-0 right-0 bg-background-secondary bg-opacity-60 z-[1000] flex p-4 md:p-8'
              >
                <div
                  ref={this.focusLockRef}
                  className='bg-background-quaternary rounded-lg border border-border-darker-yellow mx-auto w-full xl:max-h-full py-4 lg:py-8 flex flex-col relative'
                >
                  <div className='flex justify-between items-start px-4 lg:px-8'>
                    <h2 className='mini-label font-medium'>Browse segments</h2>
                    <button
                      type='button'
                      className='h-auto small-link-text text-text-grey-600'
                      onClick={this.toggleSegmentFinder}
                    >
                      Close
                      <img src={closeIcon} className='inline-block ml-2' />
                    </button>
                  </div>
                  <div
                    ref={this.segmentFinderRef}
                    className='w-full flex-1 overflow-y-auto px-4 lg:px-8'
                  >
                    <BrowseSegments
                      sectionSize='xl:h-full'
                      addSegmentsToContest={true}
                      onAddSegment={this.onAddSegmentToAddList}
                      onAddSegmentsToList={this.onAddSegmentsToList}
                      newSegments={addNewSegments}
                      existingSegments={segments}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* SEGMENT FINDER END */}
          </div>
        </ContentContainer>
      </section>
    );
  }
}
export default CreateContest;

import React, { useState, useEffect } from 'react';
import apiFetch from '../../src/js/fetch';
import BarLoader from 'react-spinners/BarLoader';

const brandsToChoose = [
  // 'Polar',
  // 'Suunto',
  'Bryton',
  'Fitbit',
  'Coros',
  // 'Wahoo',
  // 'Apple',
];

function CompatibilityNotification({ style = 'green' }) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [brands, setBrands] = useState(
    brandsToChoose.map((brand) => ({ name: brand, selected: false }))
  );
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [feedback, setFeedback] = useState(null);
  useEffect(() => {
    setShow(true); // Wait for component to mount before showing form (mitigating spam bots).
  }, []);

  useEffect(() => {
    if (email) {
      // Don't reset if email is empty
      setError(null);
      setFeedback(null);
    }
  }, [email]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!email || !brands.some((brand) => brand.selected)) {
      setError('Please fill in your email and select at least one brand.');
      return;
    }

    setError(null);
    setFeedback(null);
    setProcessing(true);

    // send message to backend
    try {
      const sent = await apiFetch('/api/compatibility-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          compatibility_notification: {
            email: email,
            brands: brands
              .filter((brand) => brand.selected)
              .map((brand) => brand.name),
          },
        }),
      });

      console.log('sent', sent);

      await setEmail('');

      setFeedback("Thanks, we'll let you know when we're ready!");
    } catch (error) {
      console.log('Error sending message:', error);
      const err = await error.json();

      setError(err.error_message);
    }

    setProcessing(false);
  };

  if (!show) return null;

  return (
    <form method='POST' className='' onSubmit={onSubmit}>
      <div className='flex flex-wrap text-text-grey-200 mb-6 max-w-[21rem] xl:max-w-none'>
        {brands.map((brand, i) => (
          <div key={i} className='flex items-center mr-4'>
            <input
              type='checkbox'
              name={brand.name}
              id={brand.name}
              checked={brand.selected}
              onChange={(e) => {
                const newBrands = [...brands];
                newBrands[i].selected = e.target.checked;
                setBrands(newBrands);
                setError(null);
              }}
              className={`${style === 'dark' ? 'dark' : ''}`}
            />
            <label
              htmlFor={brand.name}
              className={`pl-2 ${
                brand.selected
                  ? style === 'dark'
                    ? 'text-text-body'
                    : 'text-text-accent'
                  : style === 'dark'
                  ? 'text-text-grey-600'
                  : ''
              }`}
            >
              {brand.name}
            </label>
          </div>
        ))}
      </div>
      <div className='flex items-center'>
        <div className='flex-1 mr-2 max-w-sm'>
          <input
            type='email'
            name='email'
            id='email'
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
            className={`w-full input light ${
              style === 'dark' ? 'bordered' : ''
            }`}
            placeholder='Enter your e-mail'
          />
        </div>
        <button
          className={`button ${style === 'dark' ? 'dark' : ''}`}
          disabled={processing}
        >
          Notify me
        </button>
      </div>

      {processing && (
        <div className='mt-4'>
          <BarLoader color='#ffffff' />
        </div>
      )}
      {error && <p className='text-text-body-inverted mt-4 mb-0'>{error}</p>}
      {feedback && (
        <p className='mt-4 text-text-body-inverted mb-0'>{feedback}</p>
      )}
    </form>
  );
}

export default CompatibilityNotification;

import MapHandler from '../mapHandler';
import apiFetch from '../fetch';

export default class ContestSegments extends MapHandler {
  constructor(options = {}) {
    super(options);

    this.segmentLayers = [];

    this.addSegmentsToMap = this.addSegmentsToMap.bind(this);
    this.removeSegmentLayers = this.removeSegmentLayers.bind(this);
  }

  /**
   * =========================================================
   * Segments
   */
  async addSegmentsToMap(segments) {
    // console.log('Adding segments to map', segments);

    let bounds = {
      _northEast: { lat: null, lng: null },
      _southWest: { lat: null, lng: null },
    };

    // Add new segments
    let index = 0;

    for await (const segment of segments) {
      // console.log({ segment });
      let coords = null;
      if (segment.sample_file) {
        // Fetch the coordinates.
        const d = await apiFetch(segment.sample_file);
        if (d && d.coordinates) {
          coords = d.coordinates.map((c) => L.latLng(c.lat, c.lng, c.alt));
        }
      }

      if (coords) {
        // Add the segment to the map
        const layers = this.drawPolyline(coords, false);

        const tooltip = L.tooltip({ permanent: true })
          .setLatLng([segment.start_lat, segment.start_lng])
          .setContent(`S${index + 1}`)
          .addTo(this.map);

        this.segmentLayers.push({
          layer: layers.polyline,
          tooltip: tooltip,
          segmentId: segment.id,
          latLng: [segment.start_lat, segment.start_lng],
          stopIconLayer: layers.stopIcon,
          startIconLayer: layers.startIcon,
        });

        const lBounds = layers.polyline.getBounds();

        // console.log(lBounds);
        if (
          bounds._northEast.lat === null ||
          bounds._northEast.lat < lBounds._northEast.lat
        ) {
          bounds._northEast.lat = lBounds._northEast.lat;
        }
        if (
          bounds._northEast.lng === null ||
          bounds._northEast.lng < lBounds._northEast.lng
        ) {
          bounds._northEast.lng = lBounds._northEast.lng;
        }
        if (
          bounds._southWest.lat === null ||
          bounds._southWest.lat > lBounds._southWest.lat
        ) {
          bounds._southWest.lat = lBounds._southWest.lat;
        }
        if (
          bounds._southWest.lng === null ||
          bounds._southWest.lng > lBounds._southWest.lng
        ) {
          bounds._southWest.lng = lBounds._southWest.lng;
        }
      }

      index++;
    }

    // console.log(bounds);
    bounds = L.latLngBounds(
      L.latLng(bounds._northEast.lat, bounds._northEast.lng),
      L.latLng(bounds._southWest.lat, bounds._southWest.lng)
    );
    this.map.fitBounds(bounds);
  }

  removeSegmentLayers() {
    this.segmentLayers.forEach((layer) => {
      // console.log('Removing layer', layer);
      this.map.removeLayer(layer.layer);
    });
    this.segmentLayers = [];
  }
}

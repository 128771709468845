import pako from 'pako';

function apiFetch(url, options) {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(async function (response) {
        if (response.ok) {
          if (response.headers.get('content-type') === 'application/gzip') {
            console.log('Gzip - ungzip');
            const d = await response.arrayBuffer();
            const data = new Uint8Array(d);

            const parsed = JSON.parse(pako.ungzip(data, { to: 'string' }));

            return parsed;
          } else {
            return response.json();
          }
        }
        throw response;
      })
      .then(function (res) {
        resolve(res);
      })
      .catch(function (e) {
        reject(e);
      });
  });
}

export default apiFetch;

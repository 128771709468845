import React from 'react';

function ProfilePicture({ avatar, avatar_text, avatar_theme }) {
  return (
    <div
      className={`w-6 h-6 rounded-full overflow-hidden border border-border-white mr-4 ${
        avatar ? '' : avatar_theme
      }`}
    >
      {avatar ? (
        <img src={avatar} className='w-full h-full object-cover' />
      ) : (
        <span className={`avatar__text ${avatar_theme} avatar__text-small`}>
          {avatar_text}
        </span>
      )}
    </div>
  );
}

export default ProfilePicture;

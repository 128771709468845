import React from 'react';
import apiFetch from '../../src/js/fetch';

import BarLoader from 'react-spinners/BarLoader';
import Spinner from 'react-spinners/PuffLoader';
import BrowseSegmentsMap from '../../src/js/segments/browse';
import { map } from 'jquery';
import elevation from '../../images/icons/ic-elevation.svg';
import SegmentType from '../SegmentType';
import coordinates from '../../images/icons/ic-coordinates.svg';
import arrow from '../../images/icons/ic-arrow.svg';
import openInTab from '../../images/icons/ic-open-in-tab.svg';
import debounce from '../../src/js/lib/debounce';

class Browse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      loaded: false,
      initialPosition: null,
      mapSpinner: true,

      search: '',

      hovering: null,
      minZoom: false,
      createdBy: 'all',
    };

    this.addSegmentsToContest = props.addSegmentsToContest || false;
    this.refreshWindow = this.refreshWindow.bind(this);
    this.onBoundsChange = this.onBoundsChange.bind(this);

    this.mounted = true;
  }

  componentDidMount() {
    console.log('Browse mounted');

    const mapOptions = {
      mapSelector: 'browse-map',
      onBoundsChange: this.onBoundsChange,
      seeCurrentLocation: true,
    };

    let coords = null;
    if (!navigator.geolocation) {
      // Geolocation is not supported by this browser
      console.log('Geolocation is not supported by this browser.');
      this.setState({ mapSpinner: false });

      this.mapHandler = new BrowseSegmentsMap(mapOptions);
    } else {
      // Geolocation is supported by this browser
      console.log('Geolocation is supported by this browser.');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // We have the user's position
          if (this.mounted) {
            console.log({ position });

            coords = [position.coords.latitude, position.coords.longitude];
            this.setState({ initialPosition: coords, mapSpinner: false });

            mapOptions.startLocation = coords;
            this.mapHandler = new BrowseSegmentsMap(mapOptions);
          }
        },
        (error) => {
          console.log({ error });
          // We did not get the user's position
          if (this.mounted) {
            if (error.code === error.PERMISSION_DENIED) {
              // User denied the request for Geolocation
              console.log('User denied the request for Geolocation.');
            } else {
              // Geolocation is supported, and the user has not denied the request.
              console.log(
                'Geolocation is supported, and the user has not denied the request.'
              );
            }

            this.mapHandler = new BrowseSegmentsMap(mapOptions);
            this.setState({ mapSpinner: false });
          }
        }
      );
    }

    this.windowFocusListener = true;
    window.addEventListener('focus', this.refreshWindow);
  }

  refreshWindow() {
    console.log('refreshing');
    if (this.mapHandler) {
      console.log('refreshing map');
      this.onBoundsChange();
    }
  }

  componentWillUnmount() {
    // Remove the map
    console.log('unmounting Browse');
    if (this.mapHandler) {
      this.mapHandler.unmount();
      this.mapHandler = null;
    }

    if (this.windowFocusListener) {
      window.removeEventListener('focus', this.refreshWindow);
    }

    this.mounted = false;
  }

  onSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search, searching: true });

    // fetch segments by search

    if (!search) {
      this.setState({ segments: [] });
      this.fetchByBounds(this.mapHandler.getBounds());
    }

    console.log('onSearchChange');
    this.submitDebounced();
  };

  submitDebounced = debounce(() => {
    console.log('debounced');
    this.onSubmit();
  }, 600);

  onSubmit = () => {
    const { search } = this.state;

    if (search) {
      // We should then update the map with the new segments and center the map on the first matching segment (if any)
      this.setState({ segments: [] });
      this.fetchBySearch(search);
    }
  };

  onBoundsChange = (bounds, currentZoom) => {
    const { hovering, searching } = this.state;
    if (hovering) {
      // We are hovering over a segment, so we should not fetch by bounds
      return;
    }

    console.log({ searching });

    if (!bounds) {
      bounds = this.mapHandler.getBounds();
      console.log({ bounds });
    }
    if (!currentZoom) {
      currentZoom = this.mapHandler.getZoom();
      console.log({ currentZoom });
    }

    if (searching) {
      console.log('searching');
      // We just searched, so we should clear the search and don't fetch by bounds this time.
      this.setState({ searching: false });
    } else {
      // fetch segments by bounds
      if (currentZoom >= 7) {
        this.setState({ segments: [], minZoom: false });
        this.fetchByBounds(bounds);
      } else {
        console.log(`min zoom reached: ${currentZoom}`);
        this.setState({ segments: [], minZoom: true });
        this.mapHandler.addSegmentsToMap([]);
      }
    }
  };

  fetchBySearch = async (search) => {
    console.log('fetching by search');

    if (!this.mapHandler) {
      console.log('mapHandler not ready');
      return;
    }

    this.setState({ loaded: false });
    try {
      const segments = await apiFetch(
        `/api/segments/search?search=${search}&created_by=${this.state.createdBy}`
      );
      if (this.mapHandler) {
        this.setState({ segments });

        if (segments.length) {
          this.mapHandler.addSegmentsToMap(segments);
          this.mapHandler.flyTo([segments[0].stop_lat, segments[0].stop_lng]);
          this.setState({ minZoom: false });
        }
      }
    } catch (error) {
      console.log({ error });
    }
    this.setState({ loaded: true });
  };

  fetchByBounds = async (bounds) => {
    console.log('fetching by bounds');

    this.setState({ loaded: false, search: '' });
    try {
      const segments = await apiFetch(`/api/segments/bounds`, {
        method: 'POST',
        body: JSON.stringify({ bounds, created_by: this.state.createdBy }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (this.mapHandler && !this.state.minZoom) {
        this.mapHandler.addSegmentsToMap(segments);
        this.setState({ segments: segments });
      }
    } catch (error) {
      console.log({ error });
    }
    this.setState({ loaded: true });
  };

  hoverSegment = (e) => {
    const { hovering } = this.state;

    // console.log({ hovering, segmentId });
    const cont = e.currentTarget; //;.closest('[data-segment-id]');
    const segmentId = cont.dataset.segmentId;
    if (hovering !== segmentId) {
      console.log({ highlight: segmentId });

      this.setState({ hovering: segmentId }, () => {
        this.mapHandler.highlightSegment(segmentId);
      });
    }
  };
  hoverSegmentDebounced = debounce(this.hoverSegment, 100);

  endHoverSegment = (e) => {
    const cont = e.currentTarget; //.closest('[data-segment-id]');
    const segmentId = cont.dataset.segmentId;
    console.log({ unhighlight: segmentId });

    this.setState({ hovering: null });

    if (this.mapHandler) {
      this.mapHandler.unHighlightSegment(segmentId);
    }
  };
  ednHoverSegmentDebounced = debounce(this.endHoverSegment, 100);

  onChangeCreatedBy = (e) => {
    this.setState({ createdBy: e.target.value }, () => {
      this.onBoundsChange();
    });
  };

  render() {
    const { loaded, search, segments, mapSpinner, minZoom } = this.state;
    const {
      onAddSegment,
      newSegments,
      existingSegments,
      sectionSize = 'h-full',
    } = this.props;

    return (
      <section
        className={`${sectionSize} flex flex-col-reverse justify-end xl:justify-start xl:flex-row`}
      >
        <div className='w-full max-w-full overflow-hidden xl:mr-4 xl:w-2/5 xl:max-w-[27.5rem] xl:flex xl:flex-col'>
          <div className='flex'>
            <input
              type='text'
              value={search}
              onChange={this.onSearchChange}
              className='input w-full'
              placeholder='Search'
            />
            {this.addSegmentsToContest && (
              <a
                href='/segments/new?source=create-challenge'
                target='_blank'
                className='button dark whitespace-nowrap ml-2'
              >
                Create segment
              </a>
            )}
          </div>
          <div className='flex items-center'>
            <label className='mt-4 flex items-center mr-6'>
              <input
                type='radio'
                name='createdBy'
                value='all'
                checked={this.state.createdBy === 'all'}
                onChange={this.onChangeCreatedBy}
              />
              <span className='ml-2'>All segments</span>
            </label>
            <label className='mt-4 flex items-center'>
              <input
                type='radio'
                name='createdBy'
                value='me'
                checked={this.state.createdBy === 'me'}
                onChange={this.onChangeCreatedBy}
              />
              <span className='ml-2'>My segments</span>
            </label>
          </div>
          {minZoom ? (
            <div className='mt-4 text-text-grey-600'>
              Navigate to where you want to see the segments, and zoom in.
            </div>
          ) : null}
          <div className='mt-4 overflow-y-auto min-h-0 flex-1'>
            {loaded ? (
              segments.length ? (
                segments.map((segment, index) => {
                  // Combine the existing segments and the new segments (from the challenge creation segment list) when checking if a segment is already selected.
                  const checkSegments = this.addSegmentsToContest
                    ? [...existingSegments, ...newSegments]
                    : null;

                  // Check if segment is already selected (either from the existing segments or from the new segments list)
                  const existingSegment =
                    checkSegments &&
                    checkSegments.find((s) => s.id === segment.id);

                  // If segment is already in the list, disable it (must be removed from the challenge creation segment list).
                  const disabled =
                    this.addSegmentsToContest &&
                    existingSegments.find((s) => s.id === segment.id);

                  const Elem = this.addSegmentsToContest ? 'div' : 'a';
                  return (
                    <div
                      className={`w-full flex items-stretch mb-2 last:mb-0 ${
                        disabled ? 'opacity-50' : ''
                      }`}
                      key={`segment-${segment.id}`}
                    >
                      <Elem
                        href={
                          this.addSegmentsToContest ? undefined : segment.url
                        }
                        target={
                          this.addSegmentsToContest ? undefined : '_blank'
                        }
                        disabled={disabled}
                        onClick={
                          this.addSegmentsToContest
                            ? () => {
                                if (!disabled) onAddSegment(segment);
                              }
                            : undefined
                        }
                        data-segment-id={segment.id}
                        onMouseEnter={this.hoverSegment}
                        onMouseLeave={this.endHoverSegment}
                        className={`block border transition-colors ${
                          existingSegment
                            ? 'border-border-quaternary'
                            : 'hover:border-border border-transparent'
                        } flex-1 bg-background-quinary p-3 pl-4 rounded cursor-pointer flex items-center`}
                      >
                        {this.addSegmentsToContest && (
                          <div className='flex items-center border-r border-border-secondary mr-4 pr-4 h-10'>
                            <input
                              type='checkbox'
                              className='mr-2'
                              id={`segment-${segment.id}`}
                              checked={!!existingSegment}
                              disabled={disabled}
                              onChange={() => onAddSegment(segment)}
                            />
                          </div>
                        )}
                        <div className='w-full items-center small-body-text'>
                          <div className='flex justify-between mb-2 md:mb-3 items-center w-full'>
                            <div className='flex'>
                              <span className='font-medium small-body-text'>
                                {segment.name}
                              </span>
                            </div>
                            <span>S{index + 1}</span>
                          </div>
                          <div className='flex justify-between items-center flex-1'>
                            <div className='flex items-center body-text'>
                              <SegmentType
                                typeIconUrl={segment.sport_icon_url}
                              />
                              <span className='pl-2 sm:pl-4 pr-2 sm:pr-4'>
                                |
                              </span>
                              <span className='pr-2'>
                                <img src={coordinates} className='w-[14px]' />
                              </span>
                              <span>
                                {(segment.distance_in_meters / 1000).toFixed(2)}
                              </span>
                              <span>km</span>
                              <span className='pl-2 sm:pl-4 pr-2 sm:pr-4'>
                                |
                              </span>
                              <span className='pr-2'>
                                <img src={elevation} className='w-[14px]' />
                              </span>
                              <span>
                                {segment.elevation
                                  ? parseFloat(segment.elevation).toFixed(0)
                                  : '00'}
                              </span>
                              <span>m</span>
                            </div>
                            {!this.addSegmentsToContest && (
                              <img src={arrow} className='w-4' />
                            )}
                          </div>
                        </div>
                      </Elem>
                      {this.addSegmentsToContest && (
                        <a
                          href={segment.url}
                          target='_blank'
                          className={`w-[2.75rem] h-[2.75rem] rounded px-0 ml-2 border border-border-secondary flex justify-center items-center`}
                        >
                          <img src={openInTab} />
                        </a>
                      )}
                    </div>
                  );
                })
              ) : minZoom ? null : (
                <div className='text-center'>No segments found</div>
              )
            ) : (
              <BarLoader />
            )}
          </div>
          {this.addSegmentsToContest && (
            <div className='pt-4 border-t bg-white border-border absolute bottom-4 inset-x-4 md:static md:bottom-auto md:inset-x-auto md:w-full'>
              <button
                onClick={this.props.onAddSegmentsToList}
                className='button dark'
              >
                Add to the challenge and close ({newSegments.length})
              </button>
            </div>
          )}
        </div>
        <div className='xl:flex-1 flex rounded-lg overflow-hidden relative mb-4 xl:mb-0 bg-background-tertiary'>
          {mapSpinner ? (
            <div className='absolute inset-0 flex items-center justify-center'>
              <Spinner />
            </div>
          ) : null}
          <div
            id='browse-map'
            className='map w-full h-0 pb-[100%] md:pb-[70%] xl:h-auto xl:pb-0 xl:flex-1 z-0 '
          />
        </div>
      </section>
    );
  }
}
export default Browse;

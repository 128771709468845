import React from 'react';
import apiFetch from '../../../src/js/fetch';
import moment from 'moment/moment';

class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaymentForm: false,
      recurrence: 'month',
    };

    this.prices = {
      month:
        document.head.querySelector(
          'meta[name="environment:paddle_monthly_price_id"]'
        )?.content || 'pri_01hbnw1g4gwgqe1hnpetmf621g',
      year:
        document.head.querySelector(
          'meta[name="environment:paddle_yearly_price_id"]'
        )?.content || 'pri_01hbnw2prkcbnn5z5t96gz8753',
    };
  }

  eventCallback = (e) => {
    const event = e.detail;
    console.log('paddleEventCallback', event);
    const { setAllowClose } = this.props;

    if (event.name === 'checkout.payment.initiated') {
      console.log('checkout.payment.initiated');
      if (setAllowClose) setAllowClose(false);
    } else if (event.name === 'checkout.payment.failed') {
      console.log('checkout.payment.failed');
      if (setAllowClose) setAllowClose(true);
    } else if (event.name === 'checkout.completed') {
      console.log('checkout.completed');

      // Store the paddle customer ID in the database. This is used to identify the user in the webhook endpoint.
      const paddleTransactionId = event.data.transaction_id;
      // console.log({ paddleTransactionId });

      // Close the checkout
      window.Paddle.Checkout.close();

      // Poll the checkout completed endpoint
      this.checkoutCompleted(paddleTransactionId);
    } else if (
      event.name == 'checkout.loaded' ||
      event.name == 'checkout.items.updated' ||
      event.name == 'checkout.discount.removed'
    ) {
      // capture totals
      const recurringSubtotal = event.data.recurring_totals.subtotal;
      const recurringTotal = event.data.recurring_totals.total;
      const recurringTax = event.data.recurring_totals.tax;
      const discount = event.data.totals.discount;
      const todayTotal = event.data.totals.total;
      const todaySubtotal = event.data.totals.subtotal;
      const todayTax = event.data.totals.tax;
      const currencyCode = event.data.currency_code;

      console.log('checkout.loaded', {
        recurringSubtotal,
        recurringTotal,
        recurringTax,
        discount,
        todayTotal,
        todaySubtotal,
        todayTax,
        currencyCode,
      });

      this.setState({
        recurringSubtotal,
        recurringTotal,
        recurringTax,
        discount,
        todayTotal,
        todaySubtotal,
        todayTax,
        currencyCode,
      });
    } else {
      // Don't do anything. Paddle webhook notifies us about important stuff, and our webhook endpoint will update the user's info.
    }
  };

  async componentDidMount() {
    console.log('componentDidMount');

    // Paddle is loaded in the views/layouts/*.html.erb file, and it's available globally, and will emit events to the document.
    document.addEventListener('paddleEventCallback', this.eventCallback);

    // Open the checkout
    this.goToPaymentInformation();
  }

  componentWillUnmount() {
    console.log('componentWillUnmount, index');
    // Close the checkout when the component unmounts
    if (this.state.showPaymentForm && window.Paddle) {
      console.log('Closing the checkout');
      window.Paddle.Checkout.close();
    }

    if (this.checkoutPollTimeout) {
      clearTimeout(this.checkoutPollTimeout);
    }

    // Remove the paddleEventCallback listener
    document.removeEventListener('paddleEventCallback', this.eventCallback);
  }

  goToPaymentInformation = (e) => {
    // Update view to show payment form
    this.setState({ showPaymentForm: true }, () => {
      // Open the checkout
      this.openCheckout();
    });
  };

  checkoutCompleted = async (transactionId) => {
    this.setState({ polling: true });

    try {
      const result = await apiFetch('/api/paddle/checkout-completed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transaction_id: transactionId,
        }),
      });

      // console.log('result', result);

      if (!result.completed) {
        // Poll the endpoint until the transaction is completed.
        this.checkoutPollTimeout = setTimeout(() => {
          this.checkoutCompleted(transactionId);
        }, 1000);
      } else {
        // Redirect to the home page
        window.location.href = '/home?checkout=completed';
      }
    } catch (error) {
      const err = await error.json();
      console.log('Error completing the checkout:', error, err);

      this.setState({ error: err.error_message });
    }
  };

  openCheckout = async () => {
    // Show the checkout
    const { recurrence } = this.state;

    let result = null;
    try {
      result = await apiFetch('/api/paddle/prepare-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      this.setState({
        paddleMonthlyPrice: result.paddle_monthly_price,
        paddleYearlyPrice: result.paddle_yearly_price,
        paddleYearlySavedText: result.paddle_yearly_saved_text,
        paddleMonthlyDiscount: result.paddle_monthly_discount,
        paddleYearlyDiscount: result.paddle_yearly_discount,
        promoCode: result.promo_code,
      });
    } catch (error) {
      console.log({ error });

      try {
        const err = await error.json();
        this.setState({
          error: `Something went wrong preparing the checkout. Error: ${err.error_message}`,
        });
      } catch (error) {
        this.setState({
          error: `Something went wrong preparing the checkout.`,
        });
      }
    }

    if (result) {
      try {
        window.Paddle.Checkout.open({
          settings: {
            displayMode: 'inline', // set to `inline`
            theme: 'light',
            locale: 'en',
            frameTarget: 'checkout-container', // className of your checkout <div>
            frameInitialHeight: 450, // `450` or above
            frameStyle:
              'width:100%; min-width:312px; background-color: transparent; border: none;', // `min-width` must be set to `286px` or above with checkout padding off; `312px` with checkout padding on.
          },
          items: [
            {
              priceId: this.prices[recurrence],
              quantity: 1,
            },
          ],
          discountId: result.promo_code
            ? recurrence === 'month'
              ? result.promo_code.paddle_discount_monthly_id
              : result.promo_code.paddle_discount_yearly_id
            : null,
          customer: {
            id: result.customer_id,
            address: {
              countryCode: result.customer_country_code,
            },
          },
        });
      } catch (error) {
        console.log('Error opening the checkout:', error);
        this.setState({
          error: `Something went wrong opening the checkout. ${error.message}`,
        });
      }
    }
  };

  onChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    window.Paddle.Checkout.close();

    this.setState(
      {
        [name]: value,
        showPaymentForm: false,
      },
      () => {
        this.openCheckout();
      }
    );
  };

  render() {
    const {
      recurrence,
      discount,
      error,
      polling,
      paddleMonthlyDiscount,
      paddleYearlyDiscount,
      promoCode,
      paddleMonthlyPrice,
      paddleYearlyPrice,
      paddleYearlySavedText,
    } = this.state;
    return (
      <div className='text-left'>
        {discount ? (
          <p className='mini-text text-text-grey-600 mb-2'>
            Promo code: <span className='font-medium'>{promoCode.code}</span>
          </p>
        ) : null}
        <div className='mb-6'>
          <label
            className={`flex-1 mr-2 p-4 rounded border border-border block mb-2 ${
              recurrence === 'month' ? 'bg-primary' : ''
            }`}
          >
            <span className='flex items-center font-normal pb-2'>
              <input
                type='radio'
                name='recurrence'
                value='month'
                checked={recurrence === 'month'}
                onChange={this.onChange}
              />
              <span className='pl-2'>Monthly</span>
            </span>
            {discount && paddleMonthlyPrice ? (
              <>
                <span className='mini-text block font-medium'>
                  {(
                    parseFloat(paddleMonthlyPrice) *
                    (parseInt(paddleMonthlyDiscount.amount) / 100)
                  ).toFixed(2)}
                  /month
                  <span className='xmini-text text-text-grey-500 pl-1'>
                    (inc. tax)
                  </span>
                </span>
                <span className='block text-text-grey-500 mini-text mb-4 mt-1'>
                  Starting today
                </span>
                <span className='mini-text block font-medium'>
                  <span className='line-through'>
                    {paddleMonthlyPrice}/month
                  </span>
                  <span className='xmini-text text-text-grey-500 pl-1'>
                    (inc. tax)
                  </span>
                </span>
                <span className='block text-text-grey-500 mini-text mt-1'>
                  {paddleMonthlyDiscount.recur
                    ? `Starting ${moment()
                        .add(
                          paddleMonthlyDiscount.maximum_recurring_intervals,
                          'month'
                        )
                        .format('MMMM YYYY')}`
                    : 'Next billing'}
                </span>
              </>
            ) : (
              <span className='mini-text inline-block font-medium'>
                {paddleMonthlyPrice
                  ? `${paddleMonthlyPrice}/month`
                  : 'Loading price...'}
                <span className='xmini-text text-text-grey-500 pl-1'>
                  (inc. tax)
                </span>
              </span>
            )}
          </label>
          <label
            className={`flex-1 mr-2 p-4 rounded border border-border block ${
              recurrence === 'year' ? 'bg-primary' : ''
            }`}
          >
            <span className='flex items-center text-text-grey-600 pb-2'>
              <input
                type='radio'
                name='recurrence'
                value='year'
                checked={recurrence === 'year'}
                onChange={this.onChange}
              />
              <span className='pl-2'>Annually</span>
            </span>

            {discount && paddleYearlyPrice ? (
              <>
                <span className='mini-text block font-medium'>
                  {(
                    parseFloat(paddleYearlyPrice) *
                    (parseInt(paddleYearlyDiscount.amount) / 100)
                  ).toFixed(2)}
                  /year
                  <span className='xmini-text text-text-grey-500 pl-1'>
                    (inc. tax)
                  </span>
                </span>
                <span className='block text-text-grey-500 mini-text mb-4 mt-1'>
                  Starting today
                </span>
                <span className='mini-text block font-medium'>
                  <span className='line-through'>{paddleYearlyPrice}/year</span>
                  <span className='xmini-text text-text-grey-500 pl-1'>
                    (inc. tax)
                  </span>
                </span>
                <span className='block text-text-grey-500 mini-text mt-1'>
                  {paddleYearlyDiscount.recur
                    ? `Starting ${moment()
                        .add(
                          paddleYearlyDiscount.maximum_recurring_intervals,
                          'year'
                        )
                        .format('MMMM YYYY')}`
                    : 'Next billing'}
                </span>
              </>
            ) : (
              <>
                <span className='mini-text inline-block font-medium'>
                  {paddleYearlyPrice
                    ? `${paddleYearlyPrice}/year`
                    : 'Loading price...'}
                  <span className='xmini-text text-text-grey-500 pl-1'>
                    (inc. tax)
                  </span>
                </span>
                {paddleYearlySavedText ? (
                  <span className='block text-text-grey-500 mini-text'>
                    {paddleYearlySavedText}
                  </span>
                ) : null}
              </>
            )}
          </label>
        </div>

        {error ? (
          <p className='w-full border rounded border-error bg-error/10 p-4 mb-6 text-text'>
            {error}
          </p>
        ) : null}
        {polling ? (
          <div className='w-full p-8 text-center'>
            <h2 className='mx-auto'>Almost there!</h2>
            <p className='mx-auto'>
              We have emailed you details of your order. You'll be redirected
              when we've completed the necessary steps. Thank you!
            </p>
          </div>
        ) : (
          <div className='w-full checkout-container bg-background-quaternary'></div>
        )}
      </div>
    );
  }
}

export default Subscribe;

import { DirectUpload } from '@rails/activestorage';

export const uploadFile = (file) =>
  new Promise((resolve, reject) => {
    const upload = new DirectUpload(
      file,
      `/api/rails/active_storage/direct_uploads`
    );
    upload.create((error, blob) => {
      if (error) {
        reject();
      } else {
        resolve({ signed_id: blob.signed_id });
      }
    });
  });

import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import apiFetch from '../../src/js/fetch';
import Modal from '../Modal';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      reportableType: '',
      reportableId: '',
      error: null,
      processing: false,
      success: false,
    };

    this.submitListener = this.submitListener.bind(this);
    this.openReportModal = this.openReportModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);

    this.textarea = React.createRef();

    this.buttonListeners = [];
  }

  componentDidMount() {
    console.log('mounted');

    this.bodyClickListener = document.body.addEventListener(
      'click',
      this.openReportModal
    );
  }

  componentWillUnmount() {
    console.log('unmounting');
    if (this.bodyClickListener) {
      document.body.removeEventListener('click', this.openReportModal);
    }
  }

  async submitListener(e) {
    e.preventDefault();
    console.log('submitting');

    if (
      !this.state.reportableType ||
      !this.state.reportableId ||
      !this.state.text
    ) {
      console.log('missing data');
      this.setState({ error: 'Please fill out all fields.' });

      return;
    }
    this.setState({ submitting: true });

    console.log({ state: this.state });

    try {
      const data = await apiFetch(`/api/reports/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reportable: {
            reportable_type: this.state.reportableType,
            reportable_id: this.state.reportableId,
            text: this.state.text,
          },
        }),
      });
      console.log({ data });

      this.setState({ success: true, submitting: false });
    } catch (error) {
      console.log({ error });
      this.setState({ error: 'Something went wrong.', submitting: false });
    }
  }

  openReportModal(e) {
    if (!e.target.classList.contains('report-button')) {
      return;
    }

    this.setState({
      open: true,
      reportableType: e.target.dataset.reportableType,
      reportableId: parseInt(e.target.dataset.reportableId),
    });
    this.textarea.current.focus();
  }

  closeModal() {
    this.setState({
      open: false,
      success: false,
      processing: false,
      error: null,
      text: '',
      reportableType: '',
      reportableId: '',
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, error: null });
  }

  render() {
    const { open, text, error, processing, success } = this.state;
    return (
      <Modal title='Report' open={open} onClose={this.closeModal}>
        {success ? (
          <div>
            <p className=''>Thank you! We will have a look.</p>
          </div>
        ) : (
          <form
            method='POST'
            className='flex flex-col'
            onSubmit={this.submitListener}
          >
            <label htmlFor='report-text'>Tell us what's wrong.</label>
            <textarea
              ref={this.textarea}
              name='text'
              id='report-text'
              placeholder='Write here..'
              onChange={this.onChange}
              className='bg-background-quaternary my-12pt'
              value={text}
            />
            {processing ? (
              <BarLoader />
            ) : success ? null : (
              <button type='submit' className='button'>
                Submit
              </button>
            )}

            {error ? <p className=''>{error}</p> : null}
          </form>
        )}
      </Modal>
    );
  }
}
export default Reports;

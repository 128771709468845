import React from 'react';
import ContestSegmentsMap from '../../src/js/contests/segments';

class ContestPopularMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  componentDidMount() {
    this.mapHandler = new ContestSegmentsMap({
      mapSelector: 'contest-popular-map',
    });
    this.mapHandler.addSegmentsToMap(this.state.segments);
  }

  componentWillUnmount() {
    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  render() {
    return (
      <div className='flex-1 map' id='contest-popular-map'>
        Map
      </div>
    );
  }
}
export default ContestPopularMap;
